<template>
  <div class="about-title">
    <div class="d-table">
      <div class="d-table-cell">
        <div class="title-text text-center">
          <h2>{{ $t("poop-collector") }}</h2>
          <ul></ul>
        </div>
      </div>
    </div>
  </div>
  <!-- About Title Section End -->

  <!-- About Section Start -->
  <section class="about-section pb-100">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-5">
          <div class="about-image">
            <img src="assets/img/invention/1.jpg" alt="about image" />
          </div>
        </div>

        <div class="col-lg-6 offset-lg-1">
          <div class="about-text">
            <div class="section-title">
              <!-- <span>{{ $t("partnership-1-title") }}</span> -->
              <h2>{{ $t("poop-collector") }}</h2>
            </div>

            <p>
              <!-- {{ t("partnership-1-caption") }} -->
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="service-style-four pt-100 pb-70 justify-center">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <div class="service-details-area">
            <div class="service-details-img">
              <!-- Embed YouTube video -->
              <iframe
                width="100%"
                height="400"
                src="https://www.youtube.com/embed/TEajgtYUDVo?si=PVCFN7jIQ3YXbqrT"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>

            <div class="service-details-img">
              <!-- Embed YouTube video -->
              <iframe
                width="100%"
                height="400"
                src="https://www.youtube.com/embed/tNOAPvhXxH8?si=XlJFBMZTGwf7oKwC"
                frameborder="0"
                allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>

            <!-- Optional: Add text or additional content below the video if needed -->
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { useI18n } from "vue-i18n";

// Export component definition
export default {
  setup() {
    const { t } = useI18n();
    return { t };
  },
};
</script>
