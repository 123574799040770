<template>
    <div class="loader-content">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="lds-ripple">
                    <div></div>
                    <div></div>
                </div>
            </div>
        </div>
    </div>
    <!-- Pre Loader CSS End -->

    <!-- Header Section Start -->
    <header class="header-area header-style-two">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-12 col-sm-9">
                    <div class="header-left-text">
                        <ul>
                            <li>
                                <a href="tel:++357 99 699159">
                                    <i class="flaticon-call"></i>
                                    +357 99 699159
                                </a>
                            </li>
                            <li>
                                <a href="mailto:info@everb.com">
                                    <i class="flaticon-envelope"></i>
                                    panos@fsb.cy
                                </a>
                            </li>
                            <li>
                                <i class="flaticon-pin"></i>
                                CHARALAMBOU MOUSKOU 20, ABC BUSINESS CENTER BUILDING
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-5 col-sm-3">
                    <div class="header-right text-end"></div>
                </div>
            </div>
        </div>
    </header>
    <!-- Header Section End -->

    <!-- Navbar Area Start -->
    <div class="navbar-area navbar-style-two">
        <!-- Menu For Mobile Device -->
        <div class="mobile-nav" style="height: 130px">
            <router-link :to="{ name: 'home' }" class="logo">
                <img style="height: 100px" src="assets/img/logo.svg" alt="logo" />
            </router-link>

            <div style="
          position: absolute;
          top: 40px;
          right: 15px;
          z-index: 99999;
          display: flex !important;
        ">
                <!-- <div class="other-option" style="display: block !important">
          <div class="chooseLang">
            <div>
              <span
                @click="changeLanguage('el')"
                id="pt-lang"
                class="lang pt-lang chosen"
                onclick="changeLang('el', this)"
              ></span>
              <span
                value="en"
                @click="changeLanguage('en')"
                id="en-lang"
                class="lang en-lang"
                onclick="changeLang('en', this)"
              ></span>
            </div>
          </div>
        </div> -->

                <div class="other-option" style="display: block !important">
                    <div class="sidebar-toggle">
                        <button type="button" class="btn btn-demo toggle-button navbar-toggle" data-bs-toggle="modal"
                            data-bs-target="#sidebar-right">
                            <i class="flaticon-list"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <!-- cdkisjncdlknsclkn -->

        <div class="right-modal">
            <div class="modal fade right" id="sidebar-right" tabindex="-1" role="dialog">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <router-link :to="{ name: 'home' }">
                                <img src="assets/img/logo.svg" alt="logo" />
                            </router-link>
                            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <div class="modal-body">
                            <div class="container">
                                <div class="row">
                                    <div class="col-lg-12 p-0">
                                        <div class="row">
                                            <!-- <div class="col-lg-6">
                        <div class="modal-link">
                          <router-link :to="{ name: 'home' }">
                            <img
                              src="@/assets/img/menu-icons/1.jpg"
                              alt="home one"
                            />
                          </router-link>
                          <h3>
                            <router-link :to="{ name: 'home' }">{{
                              $t("menu-link__index")
                            }}</router-link>
                          </h3>
                        </div>
                      </div> -->

                                            <div class="link-modal-container">

                                                <router-link class="modal-link-design" :to="{ name: 'foods' }">{{
                                                    $t("menu-link__services-1")
                                                    }}</router-link>

                                                <router-link :to="{ name: 'invention' }">{{
                                                    $t("menu-link__services-2")
                                                    }}</router-link>

                                                <router-link :to="{ name: 'blog' }">{{
                                                    $t("menu-link__blog")
                                                    }}</router-link>

                                                <router-link :to="{ name: 'contact' }">
                                                    {{ $t("menu-link__contact") }}
                                                </router-link>
                                            </div>
                                            <!-- <div class="col-lg-6">
                                                <div class="modal-link">
                                                    <router-link :to="{ name: 'foods' }">
                                                        <img src="@/assets/img/menu-icons/2.jpg" alt="home one" />
                                                    </router-link>
                                                    <h3>
                                                        <router-link :to="{ name: 'foods' }">{{
                                                            $t("menu-link__services-1")
                                                            }}</router-link>
                                                    </h3>
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="modal-link">
                                                    <router-link :to="{ name: 'invention' }">
                                                        <img src="@/assets/img/menu-icons/3.jpg" alt="home one" />
                                                    </router-link>
                                                    <h3>
                                                        <router-link :to="{ name: 'invention' }">{{
                                                            $t("menu-link__services-2")
                                                            }}</router-link>
                                                    </h3>
                                                </div>
                                            </div>

                                            <div class="col-lg-6">
                                                <div class="modal-link">
                                                    <router-link :to="{ name: 'blog' }">
                                                        <img src="@/assets/img/menu-icons/4.jpg" alt="home one" />
                                                    </router-link>
                                                    <h3>
                                                        <router-link :to="{ name: 'blog' }">{{
                                                            $t("menu-link__blog")
                                                            }}</router-link>
                                                    </h3>
                                                </div>
                                            </div> -->

                                            <!-- <div class="col-lg-6">
                                                <div class="modal-link">
                                                    <router-link :to="{ name: 'contact' }">
                                                        <img src="@/assets/img/menu-icons/5.jpg" alt="home one" />
                                                    </router-link>
                                                    <h3>
                                                        <router-link :to="{ name: 'contact' }">
                                                            {{ $t("menu-link__contact") }}
                                                        </router-link>
                                                    </h3>
                                                </div>
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Menu For Desktop Device -->
        <div class="main-nav">
            <div class="container">
                <nav class="navbar navbar-expand-md navbar-light">
                    <router-link :to="{ name: 'home' }" class="navbar-brand">
                        <img src="assets/img/logo.svg" alt="logo" />
                    </router-link>
                    <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                        <ul class="navbar-nav m-auto">
                            <router-link class="nav-link" :to="{ name: 'home' }">{{
                                $t("menu-link__index")
                                }}</router-link>

                            <li class="nav-item">
                                <a href="#" tabindex="-1" class="nav-link dropdown-toggle">{{
                                    $t("menu-link__services")
                                    }}</a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item">
                                        <router-link class="nav-link" :to="{ name: 'foods' }">{{
                                            $t("menu-link__services-1")
                                            }}</router-link>
                                    </li>
                                    <li class="nav-item">
                                        <a href="https://www.foodoxys.com/" target="_blank" class="nav-link">{{
                                            FoodOxys
                                            }}</a>
                                    </li>


                                    <li class="nav-item">
                                        <router-link class="nav-link" :to="{ name: 'invention' }">{{
                                            $t("menu-link__services-2")
                                            }}</router-link>
                                    </li>
                                    <li class="nav-item">
                                        <a href="#" class="nav-link">{{
                                            $t("menu-link__services-3")
                                            }}</a>
                                    </li>
                                </ul>
                            </li>
                            <li class="nav-item">
                                <router-link class="nav-link" :to="{ name: 'blog' }">{{
                                    $t("menu-link__blog")
                                    }}</router-link>
                            </li>
                            <li class="nav-item">
                                <router-link class="nav-link" :to="{ name: 'contact' }">{{
                                    $t("menu-link__contact")
                                    }}</router-link>
                            </li>
                        </ul>

                        <!-- <div class="other-option">
              <div class="chooseLang">
                <div>
                  <span
                    @click="changeLanguage('el')"
                    id="pt-lang"
                    class="lang pt-lang chosen"
                  ></span>
                  <span
                    @click="changeLanguage('en')"
                    id="en-lang"
                    class="lang en-lang"
                  ></span>
                </div>
              </div>
            </div> -->

                        <div class="other-option">
                            <div class="chooseLang">
                                <div>
                                    <span @click="changeLanguage('el')" id="pt-lang" class="lang pt-lang chosen"
                                        onclick="changeLang('el', this)"></span>
                                    <span value="en" @click="changeLanguage('en')" id="en-lang" class="lang en-lang"
                                        onclick="changeLang('en', this)"></span>
                                </div>
                            </div>
                        </div>

                        <div class="other-option">
                            <div class="sidebar-toggle">
                                <button type="button" class="btn btn-demo toggle-button navbar-toggle"
                                    data-bs-toggle="modal" data-bs-target="#sidebar-right">
                                    <i class="flaticon-list"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    </div>
    <!-- <p>{{ locale }}</p> -->
    <router-view />
    <FooterPage />
</template>

<script>
import FooterPage from "@/components/footerPage.vue";
import { useI18n } from "vue-i18n";

export default {
  components: {
    FooterPage,
  },
  setup() {
    const { t, locale } = useI18n({ useScope: "global" });

    locale.value = "el"; // Change the locale as needed

    const changeLanguage = (lang) => {
      locale.value = lang;
    };

    return { t, locale, changeLanguage };
  },
};
</script>

<style lang="scss"></style>
