export default {
  menu_links: {
    index: "",
    services: {
      main: "",
      link_1: "",
      link_2: "",
      link_3: "",
    },
    blog: "blog",
    contact: "contact",
  },
  buttons: {
    read_more: "Read More",
  },

  "menu-link__index": "Home",
  "menu-link__services": "Services",
  "menu-link__services-1": "Foods - Nutrition Products",
  "menu-link__services-2": "Inventions",
  "menu-link__services-3": "Others",
  "menu-link__blog": "Blog",
  "menu-link__contact": "Contact",
  "read-more": "Read More",
  "hero-area-text":
    "The company ASTRA BLUE LTD operates in the food sector creating new products in collaboration with recognized food research laboratories from universities such as the University of Thessaly and the company FoodOxys Technovlastos.",
  "hero-area__button": "Start",
  "our_values-area__heading": "Our Values",
  "our_values-text":
    "ASTRA BLUE LTD operates in the field of food, creating innovative products in collaboration with recognized food research laboratories from universities, such as the University of Thessaly and the company FoodOxys Technovlastos.",
  "our_values-option_1": "Specializes in creating new products with patents",
  "our_values-option_2":
    "Collaborates with recognized food research laboratories",
  "our_values-option_3": "Operates in the field of food",
  "About_us-title": "About Us",
  "About_us-icon_1": "Patents in food",
  "About_us-icon_2": "Product Promotion",
  "About_us-text":
    "The company ASTRA BLUE LTD operates in the field of food creating new products (patent) in collaboration with recognized food research laboratories from universities such as the University of Thessaly and the company Technovlastos FoodOxys. The company Technovlastos of the University of Thessaly is based in the Laboratory of Physiology of Zoological Organisms of the Department of Biochemistry and Biotechnology. and the Director of the Laboratory and founder of the company is Professor Mr. Dimitrios Kouretas.",
  "Our_Partnerships-title": "Our Partnerships",
  "Our_Partnerships-text":
    "We collaborate closely with recognized research laboratories to create innovative products in the field of food. These close collaborations enhance our ability to provide high-quality products and remain leaders in our field.",
  "partnership-1-title": "Organic Olive Oil - Oregano Oil",
  "partnership-1-subtitle": "Organic BioArtuma Olive Oil - Oregano Oil",
  "partnership-1-caption":
    "The olive oil comes from Koroneiki variety olives (Olea europea), which are harvested while still green, and the oil extraction process takes place on the same day. This is evidenced by the K232 and K268 indices.",
  "partnership-2-title": "Earth Works Aegean",
  "partnership-3-title": "Biomeraki",

  "bioArtuma-text-1":
    "The vitamin E it contains is 276mg/kg of olive oil, which is 4.14mg/15ml (one tablespoon), corresponding to 28% of the recommended daily intake. The main quality indicator of olive oil is the number of polyphenols it contains. Specifically, the number of hydroxytyrosol and its derivatives. Our olive oil contains these substances in a concentration >5mg/20g of olive oil, a fact that constitutes a health claim according to EU Regulation 432/2012. Our olive oil, although certified organic, was examined for the possible presence of pesticides. The results showed that no such substance was detected. The analyses were conducted in an accredited laboratory specialized in olive oil, and only analyses from similar laboratories are considered valid. Organic Oregano Essential Oil: Oregano essential oil comes from the species Origanum vulgare hirtum, also known as Greek oregano. This species was not chosen randomly as it has been proven by studies to contain the highest amount of carvacrol compared to all oregano species worldwide. Carvacrol is a powerful antimicrobial substance with many beneficial properties. Over 200 studies have been conducted to prove the above, and upon request, we can provide you with part or all of these studies. The gold symbol on our bottle is actually a representation of the chemical compound of carvacrol. Therefore, organic bioArtuma olive oil - oregano oil is a luxurious product both externally and internally. Its use leads to the strengthening of the immune and circulatory systems.",
  "bioArtuma-text-2":
    "The story begins in 2020. The idea was to combine my knowledge and my love for the earth and create beneficial high-quality products. The two main Greek plants that dominate qualitatively on the world map based on scientific literature are oregano and olive. Oregano: It comes from the ancient Greek word 'ὀρίγανον', being a combination of the words 'ὄρος' and 'γάνος', meaning the plant that gives brightness to the mountain. Olive: The olive tree has been the symbol of Greece throughout the centuries. It is considered a sacred plant by Aristotle, Socrates, Plato, and Pythagoras. The olive branch wreath was the prize for the winners of the Olympic Games, which were born in Greece.",
  "bioArtuma-text-3":
    "Therefore, it was personally inevitable to combine the two plants with the aim of creating a unique product. Their oils contain all the beneficial substances of the plants in much higher concentrations than their fresh or dried products. Through the process of cold pressing, the exceptionally virgin olive oil is created and through the process of steam distillation, the essential oil of oregano is created. Obviously, the product is certified organic. Stavros Stagos Biologist AUTh MSc Inorganic Chemistry AUTh.",
  "partnership-button__title": "See More",
  "our-blog-title": "Our Blog",
  "our-blog-subtitle": "Our Latest Articles",
  "blog-title-1": "Personalized Thinking and Entrepreneurship",
  "blog-subhead-1":
    "“The lion inside his head had a business” Lafontaine, Fable V",
  "blog-text-1": "./el/blog-1.txt",
  "blog-title-2": "Management: Contacts that are... money",
  "blog-title-3": "How can a business manage... chaos",
  blog_writter: "Athanasios H. Papandreou",
  "our-team-title": "Our Team",
  "our-team-subtitle": "Meet our experienced team members",
  location: "Location",
  Phone: "Phone",
  food: "Food",
  "food-subhead": "Nutrition Products",
  services: {
    erga_aigaiou: {
      title: "Earthworks of the Aegean",
      subtitle: "ALTERNATIVE CROPS OF THE AEGEAN IKE",
      text: "The company was created with the aim of gathering and offering the best natural products of the island. All our products are characterized by their unique taste, authentic tradition, and high nutritional value, resulting from the special climatic conditions prevailing in Lesvos. Our goal is to give you the opportunity to discover the real treasures produced by the blessed land of this island, offering you high-quality local products.",
    },
    biomeraki: {
      subtitle: `"We are what we eat. And what we eat determines our thoughts, our life, the present, the future, and our destiny." Alexis Carrel, physician-biologist, Nobel Prize in Medicine"`,
      text: "The family of Eustratios and Eudokia Xyptera has deep knowledge and a long history in cultivation and production, and this is the greatest guarantee that our products are pure and properly grown, with love. When we decided to share with the world the good things that our land produces, we did it with full knowledge and awareness of our responsibility: to offer quality, organic products to all of you. Even more so, we wanted to share a unique characteristic that makes the difference: our passion. Thus, the name of our products emerged from the two words that characterize them, 'organic' and 'passion'. BIOMERAKI, therefore, for you to enjoy at your home, a range of pure, organic products. The extensive knowledge and experience at all stages of the production process allow the members of our family to have absolute control at every step, so that we can ensure that all the goods reaching you are of top quality! Moreover, our products are accompanied by Organic Farming Certification from TÜV AUSTRIA Hellas, while we also have a certification of traditional farming, which guarantees the high-level processing work carried out in our newly built proprietary facilities on our farm in the rich and fertile land in Plomari, beautiful Lesvos.",
    },
  },
  blogs: {
    blogOne: {
      owner: "",
      title: "",
      subtitle: "",
    },
  },
};
