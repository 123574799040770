<template>
  <div class="about-title">
    <div class="d-table">
      <div class="d-table-cell">
        <div class="title-text text-center">
          <h2>Biomeraki</h2>
        </div>
      </div>
    </div>
  </div>
  <!-- About Title Section End -->

  <!-- About Section Start -->
  <section class="about-section pb-100">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-5">
          <div class="about-image">
            <img src="assets/img/full-images/Biomeraki.jpg" alt="about image" />
          </div>
        </div>

        <div class="col-lg-6 offset-lg-1">
          <div class="about-text">
            <div class="section-title">
              <!-- <span>Biomeraki</span> -->
              <h2>Biomeraki</h2>
            </div>

            <p>
              {{ $t("services.biomeraki.subtitle") }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- About Section End -->

  <!-- Theory Section Start -->
  <!-- <div class="theory-section theory-style-two">
      <div class="container">
        <div class="row theory-area">
          <div class="col-lg-7">
            <div class="theory-text">
              <h2>My Theory is Customer Service is First</h2>
              <p>
                Quis ipsum suspendisse ultrices gravida. Risus commodo Quis
                ipsum suspendisse ultrices gravida. Risus commodo viverra
                maecenas accumsan.
              </p>

              <div class="signature-box">
                <img src="assets/img/signature.png" alt="signature" />
                <p>Babatunde Smithi</p>
                <span>Founder and CEO</span>
              </div>
            </div>
          </div>
          <div class="theory-img">
            <img src="assets/img/theory-img.png" alt="theory image" />
          </div>
        </div>
      </div>
    </div> -->
  <!-- Theory Section End -->

  <!-- Navbar Area Start -->

  <!-- Navbar Area End -->

  <!-- Modal Start  -->

  <!-- Modal End -->

  <!-- Service Title Section Start -->

  <!-- Service Title Section End -->

  <!-- Service Details Section Start -->
  <section class="service-style-four pt-100 pb-70 justify-center">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <div class="service-details-area">
            <div class="service-details-img">
              <!-- <img
                  src="assets/img/service-details.jpg"
                  alt="service details"
                /> -->
            </div>
            <!-- <h3>Online Business</h3> -->

            <p>
              {{ $t("services.biomeraki.text") }}
            </p>

            <div class="row">
              <div class="col-lg-6 col-md-6">
                <img
                  src="assets/img/portfolio/biomeraki/biomeraki-1.jpeg"
                  class="service-btm-img"
                  alt="service details"
                />
              </div>

              <div class="col-lg-6 col-md-6">
                <img
                  src="assets/img/portfolio/biomeraki/biomeraki-2.jpeg"
                  class="service-btm-img"
                  alt="service details"
                />
              </div>
              <div class="col-lg-6 col-md-6">
                <img
                  src="assets/img/portfolio/biomeraki/biomeraki-3.jpeg"
                  class="service-btm-img"
                  alt="service details"
                />
              </div>

              <div class="col-lg-6 col-md-6">
                <img
                  src="assets/img/portfolio/biomeraki/biomeraki-4.jpeg"
                  class="service-btm-img"
                  alt="service details"
                />
              </div>
              <div class="col-lg-6 col-md-6">
                <img
                  src="assets/img/portfolio/biomeraki/biomeraki-5.jpeg"
                  class="service-btm-img"
                  alt="service details"
                />
              </div>

              <div class="col-lg-6 col-md-6">
                <img
                  src="assets/img/portfolio/biomeraki/biomeraki-6.jpeg"
                  class="service-btm-img"
                  alt="service details"
                />
              </div>
              <div class="col-lg-6 col-md-6">
                <img
                  src="assets/img/portfolio/biomeraki/biomeraki-7.jpeg"
                  class="service-btm-img"
                  alt="service details"
                />
              </div>

              <div class="col-lg-6 col-md-6">
                <img
                  src="assets/img/portfolio/biomeraki/biomeraki-8.jpeg"
                  class="service-btm-img"
                  alt="service details"
                />
              </div>
              <div class="col-lg-6 col-md-6">
                <img
                  src="assets/img/portfolio/biomeraki/biomeraki-9.jpeg"
                  class="service-btm-img"
                  alt="service details"
                />
              </div>

              <div class="col-lg-6 col-md-6">
                <img
                  src="assets/img/portfolio/biomeraki/biomeraki-10.jpeg"
                  class="service-btm-img"
                  alt="service details"
                />
              </div>
              <div class="col-lg-6 col-md-6">
                <img
                  src="assets/img/portfolio/biomeraki/biomeraki-11.jpeg"
                  class="service-btm-img"
                  alt="service details"
                />
              </div>
            </div>

            <p>
              <!-- *Για όλα τα προϊόντα μας τηρούνται αυστηρά οι αρχές βιολογικής
              καλλιέργειας και εκτροφής, και είναι περιορισμένης παραγωγής. *Η
              παραγωγή μαρμελάδων, γλυκών του κουταλιού, σαλτσών κ.α. γίνεται
              μέσω της οικοτεχνίας με παραδοσιακές συνταγές χωρίς την χρήση
              χημικών. *Στα προϊόντα οικοτεχνίας ενδέχεται να εμφανιστούν
              αποκλίσεις σε χρωματισμό και γεύση, ακριβώς επειδή χρησιμοποιούμε
              πρώτες ύλες από διαφορετικές τοπικές ποικιλίες, σε διαφορετικές
              φάσεις ωρίμασης και συγκομιδής, και βέβαια χάρη στην απουσία
              χρήσης χρωστικών ουσιών και συντηρητικών.» -->
            </p>

            <div class="row">
              <div class="col-lg-6 col-md-6">
                <img
                  src="assets/img/portfolio/biomeraki/biomeraki-certificate-1.jpeg"
                  class="service-btm-img"
                  alt="service details"
                />
              </div>

              <div class="col-lg-6 col-md-6">
                <img
                  src="assets/img/portfolio/biomeraki/biomeraki-certificate-2.jpeg"
                  class="service-btm-img"
                  alt="service details"
                />
              </div>
              <div class="col-lg-6 col-md-6">
                <img
                  src="assets/img/portfolio/biomeraki/biomeraki-certificate-3.jpeg"
                  class="service-btm-img"
                  alt="service details"
                />
              </div>
              <div class="col-lg-6 col-md-6">
                <img
                  src="assets/img/portfolio/biomeraki/biomeraki-certificate-4.jpeg"
                  class="service-btm-img"
                  alt="service details"
                />
              </div>
            </div>

            <!-- <p>

              </p> -->
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { useI18n } from "vue-i18n";

// Export component definition
export default {
  setup() {
    const { t } = useI18n();
    return { t };
  },
};
</script>
