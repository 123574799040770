<template>
  <section class="portfolio-section pt-100 pb-70">
    <div class="container">
      <div class="section-title text-center">
        <span>{{ $t("menu-link__services-2") }}</span>
        <!-- <h2>{{ $t("inventions") }}</h2> -->
      </div>

      <div class="row">
        <div class="col-lg-3 col-sm-6">
          <router-link :to="{ name: 'poopCollector' }" class="portfolio-btn">
            <div class="portfolio-item">
              <div class="portfolio-img">
                <img src="assets/img/invention/1.jpg" alt="portfolio image" />
                <div class="portfolio-text">
                  <h3>{{ $t("poop-collector") }}</h3>
                  <router-link
                    :to="{ name: 'poopCollector' }"
                    class="portfolio-btn"
                  >
                    {{ $t("read-more") }}
                    <i class="flaticon-right-chevron"></i>
                  </router-link>
                </div>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { useI18n } from "vue-i18n";

// Export component definition
export default {
  setup() {
    const { t } = useI18n();
    return { t };
  },
};
</script>
