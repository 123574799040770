import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import enMessages from "@/composables/en.js";
import elMessages from "@/composables/el.js";

import { createI18n } from "vue-i18n";
const messages = {
  en: enMessages,
  el: elMessages,
};

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  next();
});

const i18n = createI18n({
  legacy: false, // you must set `false`, to use Composition API
  locale: "el", // set locale
  fallbackLocale: "en", // set fallback locale
  messages, // set locale messages
  // If you need to specify other options, you can set other options
  // ...
});

createApp(App).use(store).use(router).use(i18n).mount("#app");
