<template>
    <div class="main-banner banner-style-two">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-7 col-md-12">
                            <div class="banner-text">
                                <h1>Astra Blue Ltd</h1>
                                <p>{{ $t("hero-area-text") }}</p>

                                <div class="theme-button">
                                    <!-- <a href="#values-section" class="default-btn">{{
                    $t("hero-area__button")
                  }}</a> -->
                                    <!-- <a
                      href="https://www.youtube.com/watch?v=Fbps7_mkIT0"
                      class="video-btn popup-vimeo"
                    >
                      <i class="flaticon-play"></i>
                      {{ $t('hero-area-watch-video') }}
                    </a> -->
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-5 col-md-12">
                            <div class="banner-image">
                                <img src="assets/img/header.jpg" alt="banner image" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="particles-js"></div>
    </div>
    <!-- Banner Section End -->

    <!-- Feature Section Start -->
    <section class="features-section pt-100 pb-70" id="values-section">
        <div class="container">
            <div class="section-title text-center">
                <span></span>
                <h2>{{ $t("our_values-area__heading") }}</h2>
                <!-- <p>{{ $t("our_values-text") }}</p> -->
            </div>

            <div class="row">
                <div class="col-lg-4 col-sm-6">
                    <div class="features-card feature-one">
                        <i class="flaticon-podium"></i>
                        <h3>{{ $t("our_values-option_1") }}</h3>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="features-card feature-two">
                        <i class="flaticon-megaphone"></i>
                        <h3>{{ $t("our_values-option_2") }}</h3>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="features-card feature-three">
                        <i class="flaticon-settings"></i>
                        <h3>{{ $t("our_values-option_3") }}</h3>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Feature Section End -->

    <!-- About Section Start -->
    <section class="about-section about-style-two pb-100">
        <div class="container-fluid">
            <div class="row">
                <div class="about-image"></div>

                <div class="col-lg-7 offset-lg-5 p-0">
                    <div class="about-text">
                        <div class="section-title">
                            <span>{{ $t("About_us-title") }}</span>
                            <h2>Astra Blue LTD</h2>
                        </div>

                        <div class="row">
                            <div class="col-md-3 col-sm-4">
                                <div class="about-us">
                                    <i class="flaticon-podium icon-one"></i>
                                    <p>{{ $t("About_us-icon_1") }}</p>
                                </div>
                            </div>
                            <div class="col-md-3 col-sm-4">
                                <div class="about-us">
                                    <i class="flaticon-customer-service icon-two"></i>
                                    <p>{{ $t("About_us-icon_2") }}</p>
                                </div>
                            </div>
                        </div>

                        <p>{{ $t("About_us-text") }}</p>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="portfolio-section pt-100 pb-70">
        <div class="container">
            <div class="section-title text-center">
                <h2>{{ $t("Our_Partnerships-title") }}</h2>
                <p>{{ $t("Our_Partnerships-text") }}</p>
            </div>

            <div class="row">
                <div class="col-lg-3 col-sm-6">
                    <router-link :to="{ name: 'biologicArtima' }" class="portfolio-item">
                        <div class="portfolio-img">
                            <img src="assets/img/full-images/bio-artima.jpg" alt="portfolio image" />
                            <div class="portfolio-text">
                                <h3>{{ $t("partnership-1-title") }}</h3>
                                <div class="portfolio-btn">
                                    {{ $t("partnership-button__title") }}
                                    <i class="flaticon-right-chevron"></i>
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <router-link :to="{ name: 'ergaAigaiou' }" class="portfolio-item">
                        <div class="portfolio-img">
                            <img src="assets/img/full-images/Erga-gis-egaiou.jpg" alt="portfolio image" />
                            <div class="portfolio-text">
                                <h3>{{ $t("partnership-2-title") }}</h3>
                                <div class="portfolio-btn">
                                    {{ $t("partnership-button__title") }}
                                    <i class="flaticon-right-chevron"></i>
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>

                <div class="col-lg-6 col-sm-8">
                    <router-link :to="{ name: 'bioMeraki' }" class="portfolio-item">
                        <div class="portfolio-img">
                            <img src="assets/img/full-images/Biomeraki.jpg" alt="portfolio image" />
                            <div class="portfolio-text">
                                <h3>{{ $t("partnership-3-title") }}</h3>
                                <div class="portfolio-btn">
                                    {{ $t("partnership-button__title") }}
                                    <i class="flaticon-right-chevron"></i>
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
    </section>

    <!-- Blog Section Start -->
    <BlogsContainer />

    <!-- Blog Section End -->
</template>

<script>
import { ref } from "vue";
import BlogsContainer from "@/components/blogsContainer.vue"; // Assuming correct path

// Define reactive data

// Export component definition
export default {
  components: {
    BlogsContainer,
  },
  setup() {
    const teamMembers = ref([
      { name: "Πάρης Κανιούρας", title: "Τίτλος" },
      { name: "Ονομα Επιθετο", title: "Τίτλος" },
      { name: "Ονομα Επιθετο", title: "Τίτλος" },
      { name: "Ονομα Επιθετο", title: "Τίτλος" },
    ]);
    return {
      teamMembers,
    };
  },
};
</script>
