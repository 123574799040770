<template>
  <div class="blog-details-area pt-100 pb-70">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <div class="blog-description">
            <div class="article-image">
              <img src="../../assets/img/blog/2.jpeg" alt="blog image" />

              <div class="blog-date">
                <span>21 Feb</span>
              </div>
            </div>
            <div class="article-info">
              <ul>
                <li>
                  <i class="icofont-user-alt-2"></i>
                  {{ $t("blog_writter") }}
                </li>
              </ul>
            </div>

            <h3 class="article-title">
              {{ $t("blog-title-2") }}
            </h3>

            <article v-if="locale === 'el'">
              <p>
                Με τον <strong>Ζακ Σεγκελά,</strong> διάσημο Γάλλο διαφημιστή
                και συγγραφέα, τον άνθρωπο της επικοινωνίας που δημιούργησε το
                σλόγκαν «<strong>η ήσυχη δύναμη</strong>» και το 1981 έφερε στην
                εξουσία τον σοσιαλιστή πρόεδρο της Γαλλίας
                <strong>Φρανσουά Μιττεράν</strong>, γνωριζόμαστε 40 χρόνια
                περίπου. Και από τις εμπειρίες του, έτσι όπως ακόμη και σήμερα
                στα 90 χρόνια του τις εκφράζει, έχω συγκρατήσει πολλές. Ειδικά
                αυτές για τις σχέσεις και επαφές είναι εξόχως ενδιαφέρουσες.
              </p>
              <p>
                Σε κάθε τομέα ή επιχείρηση, υποστηρίζει, υπάρχουν πάντα οι
                άνθρωποι που μπορούν να επηρεάσουν καθοριστικά την επιτυχία.
                Αρκεί κανείς να τους επισημάνει και να αποκαταστήσει μαζί τους
                την επαφή που κερδίζει. Έτσι απλά. Μην πιστέψεις ούτε ένα λεπτό,
                λέει, πως μπορείς εσύ προσωπικά να καθορίσεις την τύχη σου στην
                δουλειά σου. Δεν μπορείς. Ξανά και ξανά,
                <strong
                  >η μοίρα σου εξαρτάται από τις αποφάσεις κάποιου άλλου</strong
                >- Η μόνη σου ελπίδα είναι να επηρεάσεις αυτές τις αποφάσεις.
              </p>
              <p>
                Σε κάθε δουλειά υπάρχουν οι
                <strong>ζωτικές γνωριμίες</strong> —άνθρωποι που έχουν την
                δυνατότητα να σε προωθήσουν ή να σε «θάψουν». Μπορούν να σε
                ανεβάσουν ή να σε χαντακώσουν. Το αφεντικό σου, ένας μεγαλύτερος
                συνάδελφος που τού έχεις εμπιστοσύνη, ένας πελάτης-κλειδί, ένας
                συγκεκριμένος υφιστάμενος, μία πηγή πληροφοριών, όλοι αυτοί ίσως
                είναι οι άνθρωποι που αποτελούν τις ζωτικές σου γνωριμίες.
              </p>
              <p>
                «Οι περισσότεροι είμαστε αρκετά έξυπνοι ώστε να αναγνωρίσουμε
                ποιες είναι οι ζωτικές μας γνωριμίες. Πολλές φορές
                <strong>παραβλέπουμε</strong>
                <strong>τις λιγότερο εμφανείς</strong>. Αυτό είναι ένα λάθος που
                κοστίζει. Εξίσου δαπανηρό είναι να παραμελείς τη σχέση σου με
                μία ζωτική γνωριμία από την στιγμή που αυτή έχει δημιουργηθεί.
                Αντί να διατηρήσεις άμεση, προσωπική και συνεχή επαφή μαζί της,
                έχεις τον πειρασμό να ασχοληθείς με πιο επείγοντα θέματα- Αυτό
                μοιάζει με το να μην ρίχνεις άλλα ξύλα στην φωτιά επειδή
                υποθέτεις ότι θα καίει συνέχεια από μόνη της.
              </p>
              <h3>Δυο είδη</h3>

              <p>
                Στις επιχειρήσεις υπάρχουν δύο είδη ζωτικών γνωριμιών: οι
                γνωριμίες <strong>status quo</strong>, δηλαδή αυτές που σε
                βοηθούν να διατηρήσεις την θέση σου χωρίς να χάσεις δύναμη ή
                έδαφος και οι γνωριμίες <strong>status excelsior</strong>,
                δηλαδή αυτές που σε βοηθούν να προχωρήσεις μπροστά. Ιδού μερικά
                παραδείγματα:
              </p>
              <ol>
                <li>
                  Για τον πρόεδρο της εταιρείας, γνωριμία status quo είναι o
                  εκτελεστικός αντιπρόεδρος της εταιρείας και γνωριμία status
                  excelsior ο πρόεδρος μιας άλλης εταιρείας την οποία προσπαθεί
                  να εξαγοράσει
                </li>
                <li>
                  Για τον υπεύθυνο δημοσίων σχέσεων, γνωριμία status quo είναι
                  ένας σημαντικός εκδότης και γνωριμία status excelsior η κόρη
                  του προέδρου του ΔΣ.
                </li>
                <li>
                  Για τον πωλητή, γνωριμία status quo είναι ένας πολύ καλός
                  πελάτης και γνωριμία status excelsior ο μεγάλος πελάτης τον
                  οποίο καλλιεργεί εδώ και έναν χρόνο.
                </li>
                <li>
                  Για τον γιο του αφεντικού, γνωριμία status quo ο μπαμπάς και
                  γνωριμία status excelsior ο μπαμπάς.
                </li>
                <li>
                  Για ένα λαμπρό νέο στέλεχος, γνωριμία status quo το αφεντικό
                  του και γνωριμία status excelsior ο άνθρωπος που συνάντησε στο
                  αεροπλάνο και θα μπορούσε να τού προσφέρει μία πολύ καλύτερη
                  θέση.
                </li>
              </ol>
              <p>
                Όσο μόνιμες κι αν φαίνονται οι ζωτικές επαφές σου, μην
                περιμένεις να διαρκέσουν για πάντα. Ελάχιστες μόνον παραμένουν-
                οι άνθρωποι στους οποίους στηρίζεσαι τώρα, ίσως δεν θα υπάρχουν
                για να στηριχθείς σε αυτούς αύριο. Ενδέχεται να μετακινηθούν
                εκείνοι, ενδέχεται να μετακινηθείς εσύ, ή μπορεί να αλλάξει η
                σχέση σου μαζί τους- οι επαγγελματικές σχέσεις μοιάζουν
                περισσότερο
                <strong>με ερωτική σχέση παρά με γάμο.</strong> Ανθίζουν και
                μαραίνονται. Μερικές φορές ξανανθίζουν, άλλες φορές πεθαίνουν.
              </p>
              <p>
                Ένας τρόπος για να μετρήσεις την ποιότητα μιας σχέσης είναι το
                <strong>πόσο συμβιβασμό</strong> <strong>απαιτεί</strong>. Κάθε
                ανθρώπινη σχέση απαιτεί κάποιον συμβιβασμό, αλλά οι καλύτερες
                απαιτούν μικρότερο. Αποφάσισε ποιο θα είναι το τίμημα που είσαι
                διατεθειμένος να πληρώσεις για να διατηρήσεις μία σημαντική
                σχέση. Αν χρειάζεται να κάνεις έναν πολύ μεγάλο συμβιβασμό και
                είναι μεγάλο το τίμημα, βρες τρόπο να τον αποφύγεις.
              </p>
              Ο κανόνας απαιτεί άμεση, προσωπική και συνεχή επαφή.<br />
              <ol>
                <li>
                  <strong>Άμεση:</strong> Αυτό σημαίνει ότι δεν θα υπάρχουν
                  ενδιάμεσοι. Στις επιχειρήσεις υπάρχουν ορισμένα πράγματα τα
                  οποία μπορείς να αναθέσεις και ορισμένα που δεν μπορείς. Το να
                  διατηρήσεις στενή επαφή με τις σημαντικές σου γνωριμίες είναι
                  κάτι που δεν μπορείς να αναθέσεις σε κάποιον άλλον. Κάνε το
                  μόνος σου.
                </li>
                <li>
                  <strong>Προσωπική:</strong> Αυτό σημαίνει, σημαίνει πρόσωπο με
                  πρόσωπο, μάτι με μάτι, ώμο με ώμο, όπου είναι δυνατόν.
                  Σημαίνει ακόμα, αν χρειαστεί, το χέρι φιλικά στην πλάτη. Τα
                  γράμματα είναι θαυμάσια. Τα τηλεφωνήματα εξαιρετικά. Αλλά
                  πρόσωπο με πρόσωπο είναι καλύτερα.
                </li>
                <li>
                  <strong>Συνεχή:</strong> Αυτό σημαίνει σχέσεις σταθερές,
                  διαρκείς, χωρίς τέλος, ασταμάτητες. Είναι το αντίθετο από τις
                  σποραδικές, τις «όταν βολεύει», τις ασταθείς και ευκαιριακές
                  σχέσεις.
                </li>
              </ol>

              <p>
                Το να παραμελείς τις ζωτικές σου γνωριμίες είναι σαν να πετάς
                χρήματα. Ακόμα χειρότερα, είναι σαν να σπαταλάς τον χρόνο σου.
              </p>
              <p>
                Πολύτιμες συμβουλές ακόμα και στην εποχή της τεχνητής
                νοημοσύνης.
              </p>
            </article>

            <article v-if="locale === 'en'">
              <p>
                With <strong>Zach Seguela,</strong> a famous French advertiser
                and author, the man of communication who coined the slogan
                "<strong>the quiet power</strong>" and in 1981 brought the
                socialist president of France,
                <strong>François Mitterrand,</strong> to power, we have known
                each other for about 40 years. And from his experiences, even as
                he expresses them today at 90 years old, I have retained many.
                Especially those about relationships and contacts are extremely
                interesting.
              </p>
              <p>
                In every field or business, he argues, there are always people
                who can significantly influence success. All it takes is to
                identify them and restore with them the contact that wins. That
                simple. Do not believe for a moment, he says, that you can
                personally determine your fate at work. You can't. Again and
                again,
                <strong>your fate depends on someone else's decisions</strong> -
                your only hope is to influence those decisions.
              </p>
              <p>
                In every job there are <strong>vital connections</strong>—people
                who have the ability to promote you or "bury" you. They can
                elevate you or ruin you. Your boss, a senior colleague you
                trust, a key client, a specific subordinate, a source of
                information, all these may be the people who constitute your
                vital connections.
              </p>
              <p>
                "Most of us are smart enough to recognize who our vital
                connections are. Many times <strong>we overlook</strong>
                <strong>the less obvious ones</strong>. This is a mistake that
                costs. Equally expensive is neglecting your relationship with a
                vital connection once it has been established. Instead of
                maintaining direct, personal, and continuous contact with them,
                you are tempted to deal with more urgent matters - This is like
                not throwing more logs on the fire because you assume it will
                keep burning on its own.
              </p>
              <h3>Two Types</h3>
              <p>
                In business, there are two types of vital connections:
                <strong>status quo</strong> connections, those that help you
                maintain your position without losing power or ground, and
                <strong>status excelsior</strong> connections, those that help
                you move forward. Here are some examples:
              </p>
              <ol>
                <li>
                  For the company president, a status quo connection is the
                  company's executive vice president, and a status excelsior
                  connection is the president of another company he is trying to
                  acquire.
                </li>
                <li>
                  For the public relations manager, a status quo connection is a
                  significant publisher, and a status excelsior connection is
                  the daughter of the chairman of the board.
                </li>
                <li>
                  For the salesperson, a status quo connection is a very good
                  customer, and a status excelsior connection is the major
                  client he has been cultivating for a year.
                </li>
                <li>
                  For the boss's son, a status quo connection is his dad, and a
                  status excelsior connection is his dad.
                </li>
                <li>
                  For a bright new executive, a status quo connection is his
                  boss, and a status excelsior connection is the person he met
                  on the plane who could offer him a much better position.
                </li>
              </ol>
              <p>
                No matter how permanent your vital connections seem, don't
                expect them to last forever. Only a few remain—those you rely on
                now may not be there for you to rely on tomorrow. They may move,
                you may move, or your relationship with them may
                change—professional relationships resemble more
                <strong>a romantic relationship than a marriage.</strong> They
                bloom and wither. Sometimes they bloom again, sometimes they
                die.
              </p>
              <p>
                One way to measure the quality of a relationship is
                <strong>how much compromise</strong>
                <strong>it requires</strong>. Every human relationship requires
                some compromise, but the best ones require less. Decide what
                price you are willing to pay to maintain an important
                relationship. If you have to make a very big compromise and the
                price is high, find a way to avoid it.
              </p>
              The rule requires direct, personal, and continuous contact.<br />
              <ol>
                <li>
                  <strong>Direct:</strong> This means there will be no
                  intermediaries. In business, there are some things you can
                  delegate and some you can't. Maintaining close contact with
                  your important connections is something you can't delegate to
                  someone else. Do it yourself.
                </li>
                <li>
                  <strong>Personal:</strong> This means face to face, eye to
                  eye, shoulder to shoulder, wherever possible. It also means,
                  if necessary, a friendly hand on the back. Letters are
                  wonderful. Phone calls are great. But face to face is better.
                </li>
                <li>
                  <strong>Continuous:</strong> This means stable, continuous,
                  endless, uninterrupted relationships. It's the opposite of
                  sporadic, "when it suits", unstable, and opportunistic
                  relationships.
                </li>
              </ol>
              <p>
                Neglecting your vital connections is like throwing away money.
                Worse, it's like wasting your time.
              </p>
              <p>Valuable advice even in the era of artificial intelligence.</p>
            </article>

            <!-- <div class="row">
                <div class="col-sm-6">
                  <img
                    src="../../assets/img/blog/4.jpg"
                    class="article-bottom-img"
                    alt="blog image"
                  />
                </div>
                <div class="col-sm-6">
                  <img
                    src="../../assets/img/blog/5.jpg"
                    class="article-bottom-img"
                    alt="blog image"
                  />
                </div>
              </div> -->

            <!-- <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
                ipsum suspendisse ultrices gravida. Risus commodo viverra
                maecenas accumsan lacus vel facilisis.
              </p>

              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
                ipsum suspendisse ultrices gravida. Risus commodo viverra
                maecenas accumsan lacus vel facilisis.
              </p> -->

            <!-- <div class="blog-nav">
                <div class="prev-btn">
                  <a href="#">Previous</a>
                </div>
                <div class="next-btn text-end">
                  <a href="#">Next</a>
                </div>
              </div> -->

            <!-- <div class="blog-comment">
                <h3>Drop your comment</h3>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Your Full Name"
                      />
                    </div>
                  </div>

                  <div class="col-md-12">
                    <div class="form-group">
                      <input
                        type="email"
                        class="form-control"
                        placeholder="Your Email"
                      />
                    </div>
                  </div>

                  <div class="col-md-12">
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Subject"
                      />
                    </div>
                  </div>

                  <div class="col-md-12">
                    <div class="form-group">
                      <textarea
                        class="message-field"
                        cols="30"
                        rows="5"
                        placeholder="Comment Here"
                      ></textarea>
                    </div>
                  </div>

                  <div class="col-md-12">
                    <button type="submit" class="default-btn contact-btn">
                      Post A Comment
                    </button>
                  </div>
                </div>
              </div> -->
          </div>
        </div>
        <!-- <div class="col-lg-4"> -->
        <!-- <div class="blog-search">
              <form>
                <input type="text" placeholder="Search" />
                <button><i class="flaticon-search"></i></button>
              </form>
            </div> -->

        <!-- <div class="recent-blog">
              <h3>Recent Blogs</h3>

              <article class="recent-post">
                <a href="#"
                  ><img
                    src="../../assets/img/blog/recent-1.png"
                    alt="recent post image"
                /></a>
                <h3>
                  <a href="#">Content writing demand day by day increasing</a>
                </h3>
                <ul>
                  <li>
                    <i class="icofont-user-alt-3"></i>
                    Admin
                  </li>
                  <li>
                    <i class="icofont-user-alt-3"></i>
                    Jan 03, 2021
                  </li>
                </ul>
              </article>

              <article class="recent-post">
                <a href="#"
                  ><img
                    src="../../assets/img/blog/recent-2.png"
                    alt="recent post image"
                /></a>
                <h3>
                  <a href="#"
                    >Don't buy a tech gift until you read these rules image</a
                  >
                </h3>
                <ul>
                  <li>
                    <i class="icofont-user-alt-3"></i>
                    Admin
                  </li>
                  <li>
                    <i class="icofont-user-alt-3"></i>
                    Jan 03, 2021
                  </li>
                </ul>
              </article>

              <article class="recent-post">
                <a href="#"
                  ><img
                    src="../../assets/img/blog/recent-3.png"
                    alt="recent post image"
                /></a>
                <h3>
                  <a href="#">The next genaration IT will change the world</a>
                </h3>
                <ul>
                  <li>
                    <i class="icofont-user-alt-3"></i>
                    Admin
                  </li>
                  <li>
                    <i class="icofont-user-alt-3"></i>
                    Jan 03, 2021
                  </li>
                </ul>
              </article>
            </div> -->
        <!--
            <div class="blog-category">
              <h3>Category</h3>

              <ul>
                <li>
                  <a href="#">
                    Business Strategy
                    <i class="icofont-arrow-right"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    Full Secured
                    <i class="icofont-arrow-right"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    Business Policy
                    <i class="icofont-arrow-right"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    Software Developmet
                    <i class="icofont-arrow-right"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    Time Saving
                    <i class="icofont-arrow-right"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    UIUX Design
                    <i class="icofont-arrow-right"></i>
                  </a>
                </li>
              </ul>
            </div>

            <div class="photo-gallery">
              <h3>Photo Gallery</h3>

              <a href="../../assets/img/blog/photo-1.jpg" class="popup-gallery">
                <img src="../../assets/img/blog/photo-1.jpg" alt="photo-gallery" />
              </a>
              <a href="../../assets/img/blog/photo-2.jpg" class="popup-gallery">
                <img src="../../assets/img/blog/photo-2.jpg" alt="photo-gallery" />
              </a>
              <a href="../../assets/img/blog/photo-3.jpg" class="popup-gallery">
                <img src="../../assets/img/blog/photo-3.jpg" alt="photo-gallery" />
              </a>
              <a href="../../assets/img/blog/photo-4.jpg" class="popup-gallery">
                <img src="../../assets/img/blog/photo-4.jpg" alt="photo-gallery" />
              </a>
              <a href="../../assets/img/blog/photo-5.jpg" class="popup-gallery">
                <img src="../../assets/img/blog/photo-5.jpg" alt="photo-gallery" />
              </a>
              <a href="../../assets/img/blog/photo-6.jpg" class="popup-gallery">
                <img src="../../assets/img/blog/photo-6.jpg" alt="photo-gallery" />
              </a>
              <a href="../../assets/img/blog/photo-7.jpg" class="popup-gallery">
                <img src="../../assets/img/blog/photo-7.jpg" alt="photo-gallery" />
              </a>
              <a href="../../assets/img/blog/photo-8.jpg" class="popup-gallery">
                <img src="../../assets/img/blog/photo-8.jpg" alt="photo-gallery" />
              </a>
            </div> -->

        <!-- <div class="tags">
              <h3>Tags</h3>
              <a href="#">UX/UI</a>
              <a href="#">Sturtup</a>
              <a href="#">App</a>
              <a href="#">Design</a>
              <a href="#">Business</a>
              <a href="#">UX</a>
              <a href="#">Writing</a>
              <a href="#">Web</a>
            </div> -->
        <!-- </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import { useI18n } from "vue-i18n";

// Export component definition
export default {
  setup() {
    const { t, locale } = useI18n();
    return { t, locale };
  },
};
</script>
