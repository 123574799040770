<template>
  <div class="about">
    <section class="portfolio-section pt-100 pb-70">
      <div class="container">
        <div class="section-title text-center">
          <span>{{ $t("food") }}</span>
          <h2>{{ $t("food-subhead") }}</h2>
        </div>

        <div class="row">
          <div class="col-lg-3 col-sm-6">
            <router-link :to="{ name: 'biologicArtima' }" class="portfolio-btn">
              <div class="portfolio-item">
                <div class="portfolio-img">
                  <img
                    src="assets/img/full-images/bio-artima.jpg"
                    alt="portfolio image"
                  />
                  <div class="portfolio-text">
                    <h3>{{ $t("partnership-1-title") }}</h3>
                    <router-link
                      :to="{ name: 'biologicArtima' }"
                      class="portfolio-btn"
                      >{{ $t("read-more") }}
                      <i class="flaticon-right-chevron"></i
                    ></router-link>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
          <div class="col-lg-3 col-sm-6">
            <router-link :to="{ name: 'ergaAigaiou' }" class="portfolio-btn">
              <div class="portfolio-item">
                <div class="portfolio-img">
                  <img
                    src="assets/img/full-images/Erga-gis-egaiou.jpg"
                    alt="portfolio image"
                  />
                  <div class="portfolio-text">
                    <h3>{{ $t("partnership-2-title") }}</h3>
                    <router-link
                      :to="{ name: 'ergaAigaiou' }"
                      class="portfolio-btn"
                      >{{ $t("read-more") }}
                      <i class="flaticon-right-chevron"></i
                    ></router-link>
                  </div>
                </div>
              </div>
            </router-link>
          </div>

          <div class="col-lg-6 col-sm-8">
            <router-link :to="{ name: 'bioMeraki' }" class="portfolio-btn">
              <div class="portfolio-item">
                <div class="portfolio-img">
                  <img
                    src="assets/img/full-images/Biomeraki.jpg"
                    alt="portfolio image"
                  />
                  <div class="portfolio-text">
                    <h3>{{ $t("partnership-3-title") }}</h3>
                    <router-link
                      :to="{ name: 'bioMeraki' }"
                      class="portfolio-btn"
                      >{{ $t("read-more") }}
                      <i class="flaticon-right-chevron"></i
                    ></router-link>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";

// Export component definition
export default {
  setup() {
    const { t } = useI18n();
    return { t };
  },
};
</script>
