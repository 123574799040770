<template>
  <div class="blog-details-area pt-100 pb-70">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <div class="blog-description">
            <div class="article-image">
              <img src="../../assets/img/blog/1.jpeg" alt="blog image" />

              <div class="blog-date">
                <span>21 Feb</span>
              </div>
            </div>
            <div class="article-info">
              <ul>
                <li>
                  <i class="icofont-user-alt-2"></i>
                  {{ $t("blog_writter") }}
                </li>
              </ul>
            </div>

            <h3 class="article-title">{{ $t("blog-title-1") }}</h3>

            <div class="post-body">
              <p>
                <em
                  ><strong>{{ $t("blog-subhead-1") }}</strong></em
                ><br />
                <!-- <em><strong>Λαφονταίν, Fable V</strong> </em> -->
              </p>
            </div>
            <p data-i18n="`blog-text-1`"></p>
          </div>

          <div v-if="locale === 'el'" class="el">
            <p>
              Είναι αποδεδειγμένο ότι η θεμελιακή διαφορά του ανθρώπου από το
              ζώο έγκειται στην ιδιότητα που έχει κάθε ανθρώπινο ον να
              λογίζεται- Επίσης, όπως αναγνωρίζουν πολλοί κορυφαίοι μελετητές
              της ανθρώπινης συμπεριφοράς, ο λογισμός είναι μία τεράστια και
              πολύπλοκη διαδικασία εξακρίβωσης και ενσωμάτωσης γνώσεων από τον
              ανθρώπινο νου, που, όπως μάς διδάσκει η Ιστορία, μόνον το
              ανθρώπινο μυαλό μπορεί να φέρει σε πέρας.
            </p>
            <p>
              Αν δεχθούμε, λοιπόν, αυτή την αλήθεια, ο οποία μέχρι στιγμής δεν
              έχει αμφισβητηθεί με σοβαρά ανθρωπολογικά επιχειρήματα, μπορούμε
              να διατυπώσουμε την άποψη ότι ο άνθρωπος σκέπτεται ατομικά.
              Συνεπώς, όπως υπογράμμιζε και η Αμερικανίδα φιλόσοφος και
              θεωρητική του αντικειμενισμού (objectivism) Αγη Rand, δεν υπάρχει
              ούτε μπορεί να υπάρξει συλλογικός νους.
            </p>

            <p>
              Κατά την Αμερικανίδα φιλόσοφο, οι άνθρωποι μπορούν να μάθουν ο
              ένας τον άλλον, αλλά η μάθηση απαιτεί μια διαδικασία λογισμού από
              πλευράς κάθε ατόμου που θέλει να μελετάει- Οι άνθρωποι μπορούν να
              συνεργάζονται για την ανακάλυψη νέας γνώσης η για την ολοκλήρωση
              κάποιου στόχου, η συνεργασία αυτή, όμως, απαιτεί την ανεξάρτητη
              άσκηση από κάθε επιστήμονα και ερευνητή των ορθολογικών του
              δυνατοτήτων και ικανοτήτων. Ο άνθρωπος είναι το μόνο ζων είδος που
              μπορεί να μεταδίδει και να επεκτείνει τα αποθέματα των γνώσεών του
              από γενιά σε γενιά.
            </p>
            <p>
              Όμως, η διαδικασία μετάδοσης, από την πλευρά των ατόμων που είναι
              αποδέκτες, απαιτεί μία διαδικασία λογισμού συναφή μόνο με την
              ανθρώπινη ζωή και την εξέλιξή της.
            </p>
            <p>
              Για να στηρίξει τη ζωή του, κάθε ζωντανός οργανισμός ακολουθεί
              έναν ορισμένο δρόμο δράσης, ο οποίος υπαγορεύεται από τη φύση του.
              Η δράση που απαιτείται για να στηριχθεί η ανθρώπινη ζωή είναι,
              πριν απ’ όλα, διανοητική. Κάθε πράγμα που χρειάζεται ο άνθρωπος,
              πρώτα το ανακαλύπτει με τον λογισμό του και στη συνέχεια το
              παράγει με την προσπάθειά του. Μπορούμε έτσι να πούμε ότι η
              παραγωγή είναι η εφαρμογή του λογισμού στο πρόβλημα της επιβίωσης-
            </p>
            <p>
              Στο πλαίσιο, λοιπόν, αυτών των διαδικασιών, οι άνθρωποι που δεν
              θέλουν να σκέπτονται μπορούν να επιβιώνουν μόνον μιμούμενοι και
              επαναλαμβάνοντας μία εργασία ρουτίνας που άλλοι ανακάλυψαν. Όμως,
              αν αυτοί οι άλλοι δεν είχαν κάνει τη συγκεκριμένη ανακάλυψη,
              κανένας δεν θα μπορούσε να επιβιώσει. Εάν μερικοί άνθρωποι
              επιλέξουν την οδό της μη εργασίας και του μη λογισμού, μπορούν να
              επιβιώσουν πρόσκαιρα, λεηλατώντας τα αγαθά που άλλοι παράγουν. Αν,
              όμως, η παραγωγή των άλλων σταματήσει, τότε θα εκλείψουν και οι
              απαραίτητες για την επιβίωση συνθήκες.
            </p>
            <p>
              Ο συγγραφέας και ιστορικός David Landes, στο ιστορικό έργο του
              «Πλούτος και Φτώχεια των Εθνών», εξηγεί γιατί στην ιστορία της
              ανθρωπότητας υπήρξαν λαοί που πλούτισαν και άλλοι που ακόμα ζουν
              στην φτώχεια και στην ανέχεια. Εξηγεί, επίσης, τους
              αντικειμενικούς λόγους κατάρρευσης της Οθωμανικής Αυτοκρατορίας,
              που ήταν ανίκανη να παράγει αλλά γνώριζε θαυμάσια την τέχνη της
              λεηλασίας.
            </p>
            <p>
              Επισημαίνει, επίσης, γιατί είναι επικίνδυνοι οι λαοί που
              αντιστέκονται στη γνώση και καταδιώκουν τον λογισμό.
            </p>
            <p>
              Ανεξάρτητα, έτσι, από τις επιλογές που γίνονται προς την
              κατεύθυνση τού σκέπτεσθαι από οποιονδήποτε άνθρωπο ή οποιαδήποτε
              ομάδα ανθρώπων, ανεξάρτητα από την ανορθόλογη, τυφλή και
              καταστροφική πορεία που θέλουν να ακολουθήσουν ορισμένοι άνθρωποι,
              υπάρχει ένα αδιαμφισβήτητο γεγονός: ο λογισμός αποτελεί καίριο
              παράγοντα για την ανθρώπινη επιβίωση και οι άνθρωποι ευημερούν ή
              αποτυχαίνουν, επιβιώνουν ή εκλείπουν ανάλογα με το βαθμό της
              ορθολογικότητάς τους. Ας μην ξεχνάμε, από την άποψη αυτή, ότι
              πρώτοι οι Αρχαίοι Έλληνες ανακάλυψαν τον ορθό λόγο, αφήνοντας πίσω
              τους τον μύθο και τον σκοταδισμό του. Είναι, λοιπόν, προφανές ότι
              η ανθρώπινη ζωή θα έπρεπε να υπακούει σε ορθολογικά κριτήρια και
              όχι σε μεταφυσικές παρορμήσεις. Αν η ανθρωπότητα στηρίζονταν στην
              ουτοπία, έγραφε παλαιότερα ο Έλληνας φιλόσοφος Κώστας Παπαϊωάννου,
              ποτέ δεν θα είχαμε ξεπεράσει το στάδιο της πρώτης γενιάς ανθρώπων.
            </p>
            <p>
              Ο πλούτος των εθνών, υποστήριζε ο Άνταμ Σμιθ, είναι προϊόν
              ορθολογικής σκέψης και δράσης και όχι αφηρημένης διανοητικής
              κατασκευής. Δεν χωρά, έτσι, καμμία αμφιβολία ότι ο λογισμός και η
              ανθρώπινη πρόοδος είναι φαινόμενα αλληλένδετα και ο άνθρωπος δεν
              μπορεί να υπάρξει αν δεν επιχειρήσει αυτό που η σκέψη του έχει
              επεξεργαστεί. Ακόμα περισσότερο, όμως, από τότε που η γνώση, ο
              λογισμός και η ορθολογική δράση είναι ιδιότητες του ατόμου και από
              τότε που η επιλογή να ασκήσει κανείς τις ορθολογικές του ιδιότητες
              εξαρτάται από το άτομο, η ανθρώπινη προσπάθεια για επιβίωση
              υπακούει σε μία θεμελιακή απαίτηση: να είναι ελεύθεροι οι άνθρωποι
              που λογίζονται σε σχέση με αυτούς που αρνούνται τον λογισμό.
              Επίσης, από τότε που οι άνθρωποι δεν είναι ούτε πανεπιστήμονες,
              αλλά ούτε και άτρωτοι, πρέπει να είναι ελεύθεροι να συμφωνούν ή να
              διαφωνούν, να συνεργάζονται ή να ακολουθούν ανεξάρτητους δρόμους,
              ο καθένας σύμφωνα με τις δικές του ορθολογικές κρίσεις. Γίνεται,
              λοιπόν, σαφές ότι η ελευθερία είναι θεμελιακή απαίτηση του
              ανθρώπινου πνεύματος —όταν, βέβαια, το τελευταίο λειτουργεί ή
              θέλει να λειτουργήσει.
            </p>
            <p>
              «Ο ορθολογικός νους δεν λειτουργεί, ωστόσο, κάτω από καταπιεστικές
              συνθήκες, δεν υποβάλλει επίσης την αντίληψή του για την
              πραγματικότητα σε κάποιες εντολές, ντιρεκτίβες και ελέγχους.
            </p>
            <p>
              Δεν θυσιάζει τη γνώση και τις απόψεις του για την αλήθεια σε
              κάποιες αντιλήψεις τρίτων, αλλά ούτε και υποτάσσεται σε ευχές,
              αλήθειες και υποσχέσεις που δεν είναι δυνατό να υποστούν κριτικό
              έλεγχο. Υπό αυτή την οπτική γωνία, ο ανθρώπινος νους μπορεί να
              προπηλακίζεται, να καταδιώκεται, να φυλακίζεται, να καταστρέφεται.
              Δεν μπορεί, όμως, να βιάζεται, να υποχρεώνεται. Το όπλο δεν
              αποτελεί επιχείρημα. Η βία και η μεταφυσική δεν είναι εργαλεία
              υποβοηθητικά της σκέψης που θέλει να αναπτύσσεται», γράφει η Αγη
              Rand, εξηγώντας ταυτόχρονα γιατί ο καλώς εννοούμενος εγωισμός
              «είναι ζωτική πηγή της ύπαρξης».
            </p>
            <p>
              Κατά συνέπεια, η επιχείρηση, από την πρωτογενή μορφή της μέχρι τις
              σημερινές διαστάσεις της, είναι η έμπρακτη απεικόνιση του
              ανθρώπινου λογισμού, που σε κάποια ιστορική φάση της εξελικτικής
              του πορείας έγινε ορθός λόγος και, στη συνέχεια, πράξη.
            </p>
          </div>

          <div v-if="locale === 'en'" class="en">
            <p>
              It is proven that the fundamental difference between humans and
              animals lies in the ability of every human being to reason. As
              many leading scholars of human behavior acknowledge, reasoning is
              a huge and complex process of verification and integration of
              knowledge by the human mind, which, as History teaches us, only
              the human mind can accomplish.
            </p>
            <p>
              Therefore, if we accept this truth, which has not been seriously
              challenged with anthropological arguments so far, we can argue
              that humans think individually. Thus, as emphasized by the
              American philosopher and theorist of objectivism, Ayn Rand, there
              is neither nor can there be a collective mind.
            </p>

            <p>
              According to the American philosopher, people can learn from each
              other, but learning requires a process of reasoning on the part of
              each individual who wants to study. People can collaborate to
              discover new knowledge or to achieve a goal, but this
              collaboration requires independent exercise by each scientist and
              researcher of their rational capabilities and abilities. Humans
              are the only species that can transmit and extend the stock of
              their knowledge from generation to generation.
            </p>
            <p>
              However, the transmission process, from the perspective of the
              recipients, requires a reasoning process related only to human
              life and its evolution.
            </p>
            <p>
              To support life, every living organism follows a certain course of
              action dictated by its nature. The action required to support
              human life is primarily intellectual. Everything a human needs, he
              first discovers through his reasoning and then produces through
              his effort. Thus, we can say that production is the application of
              reasoning to the problem of survival.
            </p>
            <p>
              In the context of these processes, people who do not want to think
              can only survive by imitating and repeating a routine task
              discovered by others. However, if those others had not made the
              particular discovery, no one would be able to survive. If some
              people choose the path of non-work and non-reason, they can
              survive temporarily, looting the goods produced by others.
              However, if the production of others stops, then the necessary
              conditions for survival will disappear.
            </p>
            <p>
              The author and historian David Landes, in his historical work "The
              Wealth and Poverty of Nations," explains why in the history of
              humanity there have been peoples who have become rich and others
              who still live in poverty and want. He also explains the objective
              reasons for the collapse of the Ottoman Empire, which was unable
              to produce but knew wonderfully the art of plundering.
            </p>
            <p>
              He also points out why peoples who resist knowledge and persecute
              reasoning are dangerous.
            </p>
            <p>
              Regardless of the choices made towards thinking by any individual
              or group of people, regardless of the irrational, blind, and
              destructive path that some people want to follow, there is an
              undisputed fact: reasoning is a crucial factor for human survival,
              and people prosper or fail, survive or perish depending on the
              degree of rationality. Let's not forget, from this perspective,
              that the Ancient Greeks were the first to discover correct
              reasoning, leaving behind myth and obscurantism. It is, therefore,
              obvious that human life should be subject to rational criteria and
              not to metaphysical impulses. If humanity relied on utopia, as the
              Greek philosopher Kostas Papaioannou wrote in the past, we would
              never have overcome the stage of the first generation of humans.
            </p>
            <p>
              The wealth of nations, argued Adam Smith, is the product of
              rational thought and action and not of abstract intellectual
              construction. Therefore, there is no doubt that reasoning and
              human progress are interrelated phenomena, and man cannot exist
              unless he attempts what his thought has processed. Moreover, since
              knowledge, reasoning, and rational action are properties of the
              individual, and since the choice to exercise one's rational
              properties depends on the individual, human endeavor for survival
              is subject to a fundamental requirement: that people who think are
              free in relation to those who deny reason. Also, since people are
              neither omniscient nor infallible, they must be free to agree or
              disagree, to cooperate or to follow independent paths, each
              according to their rational judgments. It is therefore clear that
              freedom is a fundamental demand of the human spirit — when, of
              course, it functions or wants to function.
            </p>
            <p>
              "The rational mind does not operate, however, under oppressive
              conditions, nor does it submit its perception of reality to some
              commands, directives, and controls. It does not sacrifice its
              knowledge and views of truth to some third-party perceptions, but
              neither does it submit to wishes, truths, and promises that cannot
              undergo critical scrutiny. From this point of view, the human mind
              can be attacked, pursued, imprisoned, destroyed. However, it
              cannot be violated, it cannot be coerced. The weapon is not an
              argument. Violence and metaphysics are not auxiliary tools of
              thought wanting to develop," writes Ayn Rand, explaining at the
              same time why rational selfishness "is a vital source of
              existence."
            </p>
            <p>
              Therefore, enterprise, from its primitive form to its current
              dimensions, is the practical representation of human reasoning,
              which at some historical stage of its evolution became correct
              speech and then action.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useI18n } from "vue-i18n";

// Export component definition
export default {
  setup() {
    const { t, locale } = useI18n();

    return { t, locale };
  },
};
</script>
