<template>
  <BlogsContainer />
  <!-- Use the tag name of the component, which is "BlogsContainer" -->
</template>

<script>
import BlogsContainer from "@/components/blogsContainer.vue"; // Capitalize the component name

export default {
  components: {
    BlogsContainer, // Use the same key-value pair as the component name
  },
};
</script>
