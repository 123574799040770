<template>
  <div>
    <!-- Contact Title Section End -->

    <!-- Contact Card Section Start -->
    <div class="contact-area" style="margin-top: 200px">
      <div class="container">
        <div class="row contact-box">
          <div class="col-lg-4 col-md-6">
            <div class="contact-card">
              <i class="flaticon-pin"></i>
              <h3>{{ $t("location") }}</h3>
              <ul>
                <li>CHARALAMBOU MOUSKOU 20, ABC BUSINESS CENTER BUILDING</li>
              </ul>
            </div>
          </div>

          <div class="col-lg-4 col-md-6">
            <div class="contact-card">
              <i class="flaticon-call"></i>
              <h3>{{ $t("Phone") }}</h3>
              <ul>
                <li>+357 99 699159</li>
              </ul>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
            <div class="contact-card">
              <i class="flaticon-envelope"></i>
              <h3>Email</h3>
              <ul>
                <li>panos@fsb.cy</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Contact Card Section End -->

    <!-- Map Section Start -->
    <div class="map" style="margin-top: 100px">
      <div class="container-fluid p-0">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1096.7907353486!2d32.426485193700096!3d34.77623538168722!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14e707052a9c25f3%3A0xc82313160436ce63!2sABC%20Building!5e0!3m2!1sel!2sgr!4v1707826665312!5m2!1sel!2sgr"
          aria-hidden="false"
          tabindex="0"
        ></iframe>
      </div>
    </div>
    <!-- Map Section End -->
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";

// Export component definition
export default {
  setup() {
    const { t } = useI18n();
    return { t };
  },
};
</script>
