import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import BlogView from "../views/BlogView.vue";
import ContactView from "../views/ContactView.vue";
import ServiceFoods from "@/views/services/serviceFoods.vue";
import ServiceInvention from "@/views/services/serviceInvention.vue";
import FoodBio from "@/views/services/foods/biologikoArtima.vue";
import FoodBiomeraki from "@/views/services/foods/bioMeraki.vue";
import FoodErgaAigaiou from "@/views/services/foods/ergaAigaiou.vue";
import poopCollector from "@/views/services/invention/poopCollector.vue";
import blogOne from "@/views/blogs/blogOne.vue";
import blogTwo from "@/views/blogs/blogTwo.vue";
import blogThree from "@/views/blogs/blogThree.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/services",
    name: "services",
    redirect: { name: "foods" }, // Redirect to one of the child routes under "/foods"
  },
  {
    path: "/foods",
    name: "foods",
    component: ServiceFoods,
  },
  {
    name: "biologicArtima",
    path: "/biologicArtima", // Remove the leading slash
    component: FoodBio,
  },
  {
    name: "ergaAigaiou",
    path: "/ergaAigaiou", // Remove the leading slash
    component: FoodErgaAigaiou,
  },
  {
    name: "bioMeraki",
    path: "/bioMeraki", // Remove the leading slash
    component: FoodBiomeraki,
  },
  {
    path: "/invention",
    name: "invention",
    component: ServiceInvention,
  },

  {
    path: "/poopCollector",
    name: "poopCollector",
    component: poopCollector,
  },
  {
    path: "/blog",
    name: "blog",
    component: BlogView,
  },
  {
    path: "/blogOne",
    name: "blogOne",
    component: blogOne,
  },
  {
    path: "/blogTwo",
    name: "blogTwo",
    component: blogTwo,
  },
  {
    path: "/blogThree",
    name: "blogThree",
    component: blogThree,
  },
  {
    path: "/contact",
    name: "contact",
    component: ContactView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

export default router;
