<template>
  <!-- Footer Section Start -->
  <footer class="footer-area">
    <div class="container">
      <div class="row">
        <div class="col-md-3 col-sm-6">
          <div class="footer-widget">
            <div class="footer-logo">
              <router-link :to="{ name: 'home' }" href="/index.html">
                <img src="/assets/img/logo.svg" alt="logo" />
              </router-link>
            </div>
            <!-- <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna.
              </p> -->

            <div class="footer-social">
              <a
                href="https://www.facebook.com/profile.php?id=100094752295895"
                target="_blank"
                ><i class="flaticon-facebook"></i
              ></a>
              <!-- <a href="#"><i class="flaticon-twitter"></i></a>
              <a href="#"><i class="flaticon-instagram"></i></a>
              <a href="#"><i class="flaticon-linkedin"></i></a> -->
            </div>
          </div>
        </div>
        <!--
          <div class="col-md-3 col-sm-6">
            <div class="footer-widget pl-75">
              <h3>Services</h3>
              <ul>
                <li>
                  <a href="service.html">
                    <i class="flaticon-right-chevron"></i>
                    Development
                  </a>
                </li>
                <li>
                  <a href="service.html">
                    <i class="flaticon-right-chevron"></i>
                    Business
                  </a>
                </li>
                <li>
                  <a href="service.html">
                    <i class="flaticon-right-chevron"></i>
                    Designing
                  </a>
                </li>
                <li>
                  <a href="service.html">
                    <i class="flaticon-right-chevron"></i>
                    Branding
                  </a>
                </li>
                <li>
                  <a href="service.html">
                    <i class="flaticon-right-chevron"></i>
                    Network
                  </a>
                </li>
              </ul>
            </div>
          </div> -->

        <div class="col-md-3 col-sm-6"></div>

        <div class="col-md-3 col-sm-6">
          <div class="footer-widget pl-75">
            <h3>Find Us</h3>
            <ul>
              <li style="line-height: 2">
                <i class="flaticon-right-chevron"></i>
                CHARALAMBOU MOUSKOU 20, ABC BUSINESS CENTER BUILDING
              </li>
              <!-- <li>
                <a href="tel:+13325845845">
                  <i class="flaticon-right-chevron"></i>
                  +13325845845
                </a>
              </li> -->
              <li class="linkList">
                <a href="tel: +357 99 699159">
                  <i class="flaticon-right-chevron"></i>
                  +357 99 699159
                </a>
              </li>
              <!-- <li>
                <a href="mailto:info@everb.com">
                  <i class="flaticon-right-chevron"></i>
                  info@everb.com
                </a>
              </li> -->
              <li class="linkList">
                <a href="mailto: panos@fsb.cy">
                  <i class="flaticon-right-chevron"></i>
                  panos@fsb.cy
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="copyright-area">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-6 lh-1 text-left">
            <p>&copy; 2024 All Rights Reserved by Astra Blue Ltd</p>
          </div>
          <div class="col-md-6 lh-1 text-end">
            <ul>
              <li><a href="privacy.html">Privacy Policy</a></li>
              <li><a href="#">Terms & Conditions</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "footerPage",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
