<template>
  <div class="about-title">
    <div class="d-table">
      <div class="d-table-cell">
        <div class="title-text text-center">
          <h2>{{ $t("partnership-1-title") }}</h2>
          <ul></ul>
        </div>
      </div>
    </div>
  </div>
  <!-- About Title Section End -->

  <!-- About Section Start -->
  <section class="about-section pb-100">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-5">
          <div class="about-image">
            <img src="assets/img/3.jpeg" alt="about image" />
          </div>
        </div>

        <div class="col-lg-6 offset-lg-1">
          <div class="about-text">
            <div class="section-title">
              <span>{{ $t("partnership-1-title") }}</span>
              <h2>{{ $t("partnership-1-subtitle") }}</h2>
            </div>

            <p>
              {{ t("partnership-1-caption") }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="service-style-four pt-100 pb-70 justify-center">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <div class="service-details-area">
            <div class="service-details-img">
              <!-- <img
                  src="assets/img/service-details.jpg"
                  alt="service details"
                /> -->
            </div>
            <!-- <h3>Online Business</h3> -->

            <p>
              {{ t("bioArtuma-text-1") }}
            </p>

            <p>
              {{ t("bioArtuma-text-2") }}
            </p>

            <div class="row">
              <div class="col-lg-6 col-md-6">
                <img
                  src="assets/img/portfolio/oil-tree.jpg"
                  class="service-btm-img"
                  alt="service details"
                />
              </div>

              <div class="col-lg-6 col-md-6">
                <img
                  src="assets/img/portfolio/oregano.jpg"
                  class="service-btm-img"
                  alt="service details"
                />
              </div>
            </div>

            <div class="row">
              <div class="col-lg-6 col-md-6">
                <img
                  src="assets/img/1.jpeg"
                  class="service-btm-img"
                  alt="service details"
                />
              </div>

              <div class="col-lg-6 col-md-6">
                <img
                  src="assets/img/2.jpeg"
                  class="service-btm-img"
                  alt="service details"
                />
              </div>

              <div class="col-lg-6 col-md-6">
                <img
                  src="assets/img/3.jpeg"
                  class="service-btm-img"
                  alt="service details"
                />
              </div>
            </div>

            <div class="video-container">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/CeIboIsTNtw?si=BU7JXWdmBzED_3CZ"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            </div>

            <p>{{ t("bioArtuma-text-3") }}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { useI18n } from "vue-i18n";

// Export component definition
export default {
  setup() {
    const { t } = useI18n();
    return { t };
  },
};
</script>
