<template>
  <div class="blog-details-area pt-100 pb-70">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <div class="blog-description">
            <div class="article-image">
              <img src="../../assets/img/blog/2.jpeg" alt="blog image" />

              <div class="blog-date">
                <span>21 Feb</span>
              </div>
            </div>
            <div class="article-info">
              <ul>
                <li>
                  <i class="icofont-user-alt-2"></i>
                  {{ $t("blog_writter") }}
                </li>
              </ul>
            </div>

            <h3 class="article-title">
              {{ $t("blog-title-3") }}
            </h3>

            <article v-if="locale === 'el'" class="mt10" id="textforresizing">
              <p>
                Θυμάμαι τον sir <strong>John Browne</strong>, γενικό διευθυντή
                του ομίλου της ΒΡ, λίγο πριν την πτώση της εταιρείας να λέει:
                «....Η στιγμή που εγκαταλείπεις την ψευδαίσθηση ότι μπορείς να
                προβλέψεις το μέλλον είναι μια στιγμή που σου δίνει τη χαρά της
                <strong>απελευθέρωσης</strong>. Το μόνο που μπορείς να κάνεις,
                πλέον, είναι να δώσεις στον εαυτό σου τη δυνατότητα να
                ανταποκριθεί στη μοναδική βεβαιότητα που υπάρχει στη ζωή - που
                είναι <strong>η... αβεβαιότητα</strong>. Η δημιουργία αυτής της
                δυνατότητας είναι ο σκοπός της στρατηγικής...».
              </p>
              <p>
                Λίγο αργότερα, ένας γκουρού του μάνατζμεντ, τόνισε με έμφαση ότι
                «...σε εποχές κατά τις οποίες επικρατεί χάος, το παραδοσιακό
                τριετές στρατηγικό σχέδιο είναι
                <strong>αναχρονιστικό και χωρίς καμία αξία</strong>. Πράγματι, η
                παραδοσιακή προσέγγιση της στρατηγικής απαιτεί ακριβείς
                προβλέψεις, γεγονός που συχνά οδηγεί τα υψηλόβαθμα στελέχη να
                υποτιμήσουν την αβεβαιότητα και το χάος που προκαλείται από
                απρόβλεπτες και επαναλαμβανόμενες αναταράξεις. Στην Εποχή των
                Αναταράξεων η προσέγγιση αυτή μπορεί να είναι
                <strong>πάρα πολύ επικίνδυνη</strong> στον καθορισμό των
                στρατηγικών τους. Όταν τα προσεγγίσουμε με αυτόν τον τρόπο,
                μερικές ομάδες γεγονότων αποκτούν περισσότερη σημασία απ' ό,τι
                άλλες.
              </p>
              <p>
                Συνεπώς, οι μάνατζερ μπορούν να βελτιώσουν την αναζήτησή τους
                για πληροφορίες, να ψάξουν για περαιτέρω ενδείξεις και μοντέλα
                και να ελέγξουν τις ιδέες τους και τις στρατηγικές αντιδράσεις
                τους. Η βασική αξία ενός τέτοιου σχεδιασμού σεναρίων είναι ότι
                επιτρέπει στους ηγέτες επιχειρήσεων να<strong>
                  «δοκιμάσουν το μέλλον</strong
                >», μια ευκαιρία που δεν παρουσιάζεται στις καθημερινές συνθήκες
                όπου κάθε ενέργεια και κάθε απόφαση παίζει ρόλο.
              </p>

              <p>
                Παραθέτουμε μια αποτελεσματική και αποδοτική μέθοδο για την
                κατάστρωση σεναρίων, όπως αυτή περιγράφεται από τον
                <strong>Φ.Κότλερ</strong>:
              </p>
              <ol>
                <li>
                  Αποφασίστε ποιο θα είναι το κεντρικό ερώτημα που πρέπει να
                  απαντηθεί από την <strong>ανάλυση σεναρίων</strong>. Στη
                  συνέχεια είναι δυνατό να αξιολογήσετε αν ο σχεδιασμός σεναρίου
                  είναι προτιμότερος από άλλες μεθόδους ή από αναλύσεις κατ'
                  αναλογία.
                </li>
                <li>
                  Καθορίστε
                  <strong>το πεδίο και τον χρόνο της ανάλυσης</strong>. Λάβετε
                  υπόψη σας πόσο γρήγορα συνέβησαν στο παρελθόν αλλαγές και
                  προσπαθήστε να αξιολογήσετε σε ποιο βαθμό είναι πιθανό να
                  προβλέπετε τις τάσεις στα δημογραφικά δεδομένα, στους κύκλους
                  ζωής των προϊόντων ή σε άλλες κατηγορίες ενδιαφέροντος.
                </li>
                <li>
                  Εντοπίστε τις
                  <strong>σημαντικότερες ομάδες συμφερόντων</strong> που
                  σχετίζονται με την επιχείρηση. Αποφασίστε ποιοι θα επηρεαστούν
                  και ποιοι έχουν συμφέρον από τα πιθανά αποτελέσματα. Βρείτε τα
                  τρέχοντα ενδιαφέροντά τους και αν, και γιατί, έχουν αλλάξει
                  στο παρελθόν.
                </li>
                <li>
                  Αποτυπώστε τις βασικές τάσεις και τις αναταράξεις, καθώς και
                  τις <strong>προκύπτουσες δυνάμεις χάους</strong>. Η αποτύπωση
                  αυτή αφορά, μεταξύ άλλων, τις τάσεις στον κλάδο, στους
                  ανταγωνιστές, στις οικονομικές, τις πολιτικές, τις
                  τεχνολογικές, τις νομικές και τις κοινωνικές τάσεις.
                  Χρησιμοποιήστε τεχνικές συνεδριών συνεργατικής αναζήτησης για
                  να αξιολογήσετε σε ποιο βαθμό οι τάσεις αυτές επηρεάζουν τα
                  θέματα της έρευνάς σας. Στη συνέχεια περιγράψτε κάθε τάση,
                  μεταξύ των οποίων το πώς και το γιατί θα επηρεάσει, τον
                  οργανισμό και την επιχείρησή σας.
                </li>
                <li>
                  Εντοπίστε <strong>τις βασικές αβεβαιότητες</strong> που
                  προκύπτουν από το χάος. Συμπεριλάβετε εκείνες τις δυνάμεις του
                  χάους που θα έχουν σημαντικό αντίκτυπο στον κλάδο, στην αγορά
                  και στην επιχείρησή σας. Αξιολογήστε αν υπάρχουν σχέσεις
                  ανάμεσα σε διάφορες δυνάμεις του χάους και αποκλείατε τα όποια
                  «απίθανα» σενάρια.
                </li>
                <li>
                  Δώστε τον <strong>ορισμό των βασικών σεναρίων</strong>.
                  Συνήθως καταστρώνονται δύο έως τέσσερα σενάρια. Απεικονίστε τα
                  σχηματικά, αν μπορείτε. Μια μέθοδος είναι να τεθούν όλα τα
                  θετικά στοιχεία σε ένα σενάριο και όλα τα αρνητικά στοιχεία σε
                  ένα άλλο. Στη συνέχεια μπορείτε να βελτιώσετε τα υπόλοιπα
                  σενάρια. Αποφύγετε τα σενάρια της καλύτερης και της χειρότερης
                  περίπτωσης. Βρείτε και διεξάγετε την όποια επιπλέον έρευνα
                  μπορεί να είναι ακόμη απαραίτητη.
                </li>
                <li>
                  <strong>Αξιολογήστε τα βασικά σενάρια</strong>. Έχουν κάποια
                  σχέση με τον στόχο; Διαθέτουν εσωτερική συνέπεια; Έχουν
                  αρχετυπικό χαρακτήρα; Αντιπροσωπεύουν σχετικά σταθερά
                  αποτελέσματα;
                </li>
                <li>
                  Συγκλίνετε προς σενάρια λήψης αποφάσεων. Ακολουθήστε τα
                  προηγούμενα επτά βήματα στο πλαίσιο μιας επαναλαμβανόμενης
                  διαδικασίας μέχρι να καταλήξετε σε σενάρια που αντιμετωπίζουν
                  τα θεμελιώδη ζητήματα της επιχείρησης. Αξιολογήστε τα θετικά
                  και τα αρνητικά σημεία κάθε σεναρίου και στη συνέχεια
                  <strong>ιεραρχήστε τα ένα-ένα</strong> με βάση μια αξιολόγηση
                  της πιθανότητάς του.
                </li>
              </ol>
              <p>Συμπέρασμα:</p>
              <p>
                Σήμερα, αλλά και στο μέλλον, μπορεί να μην έχει και τόσο κρίσιμη
                σημασία το τι διαθέτουν και τι παράγουν οι επιχειρήσεις, όσο
                είναι κρίσιμη η σημασία του να ρωτήσουμε για την ικανότητά τους
                να ανιχνεύουν τις αναταράξεις, να προβλέπουν το χάος και<strong>
                  να διαχειρίζονται τον κίνδυνο</strong
                >. Ο εντοπισμός και η διαχείριση του κινδύνου είναι κάθε άλλο
                παρά εύκολη υπόθεση. Η κατάστρωση σεναρίων και στρατηγικών για
                την αντιμετώπιση των αναμενόμενων κινδύνων, και αντίστροφα για
                την εκμετάλλευση ευκαιριών, προϋποθέτει ότι οι ηγέτες των
                επιχειρήσεων θα εμφυσήσουν νέες μορφές στρατηγικής συμπεριφοράς
                και αρχές στον οργανισμό που διοικούν.
              </p>
              <p>
                Και όταν αυτές οι νέες και αναγκαίες συμπεριφορές ενσωματωθούν
                στις καθημερινές διαδικασίες λήψης αποφάσεων, αυτό δημιουργεί
                μια δυναμική και μια κουλτούρα η οποία θα αντιμετωπίζει σε
                μόνιμη βάση το χάος των αναταράξεων και πάντα θα κερδίζει τις
                μάχες κατά των ανταγωνιστών.
              </p>
              <p>
                Βασική προϋπόθεση; Κάποιοι ιθύνοντές τους
                <strong>να εγκαταλείψουν την παντογνωσία</strong>.
              </p>
            </article>

            <article v-if="locale === 'en'" class="mt10" id="textforresizing">
              <p>
                I remember Sir John Browne, CEO of BP, saying just before the
                company's downfall: "The moment you abandon the illusion that
                you can predict the future is a moment that gives you the joy of
                liberation. All you can do now is give yourself the ability to
                respond to the only certainty in life - which is uncertainty.
                Creating this ability is the purpose of strategy..."
              </p>
              <p>
                A guru of management later emphasized that "...in times of
                chaos, the traditional three-year strategic plan is outdated and
                worthless. Indeed, the traditional approach to strategy requires
                precise predictions, which often lead senior executives to
                underestimate the uncertainty and chaos caused by unpredictable
                and recurring disruptions. In the Age of Turbulence, this
                approach can be very dangerous in determining their strategies.
                When we approach them in this way, some events become more
                important than others.
              </p>
              <p>
                Therefore, managers can improve their search for information,
                search for further clues and models, and check their ideas and
                strategic responses. The basic value of such scenario planning
                is that it allows business leaders to 'test the future', an
                opportunity that does not arise in everyday conditions where
                every action and decision plays a role.
              </p>
              <p>
                We present an effective and efficient method for scenario
                planning, as described by F. Kotler:
              </p>
              <ol>
                <li>
                  Decide what the central question to be answered by scenario
                  analysis will be. Then you can assess whether scenario
                  planning is preferable to other methods or analogical
                  analyses.
                </li>
                <li>
                  Determine the scope and time of the analysis. Consider how
                  quickly changes have occurred in the past and try to assess to
                  what extent it is possible to predict trends in demographic
                  data, product life cycles, or other areas of interest.
                </li>
                <li>
                  Identify the most important interest groups related to the
                  business. Decide who will be affected and who has interests in
                  the potential outcomes. Find out their current interests and
                  if, and why, they have changed in the past.
                </li>
                <li>
                  Capture the main trends and disruptions, as well as emerging
                  chaos forces. This capture involves, among other things,
                  trends in the industry, competitors, economic, political,
                  technological, legal, and social trends. Use cooperative
                  search session techniques to assess to what extent these
                  trends affect your research topics. Then describe each trend,
                  including how and why it will affect your organization and
                  business.
                </li>
                <li>
                  Identify the key uncertainties arising from chaos. Include
                  those chaos forces that will have a significant impact on the
                  industry, the market, and your business. Evaluate if there are
                  relationships between various chaos forces and exclude any
                  "unlikely" scenarios.
                </li>
                <li>
                  Define the basic scenarios. Usually, two to four scenarios are
                  developed. Represent them schematically, if possible. One
                  method is to put all positive elements in one scenario and all
                  negative elements in another. Then you can refine the
                  remaining scenarios. Avoid the best-case and worst-case
                  scenarios. Find and conduct any additional research that may
                  still be necessary.
                </li>
                <li>
                  Evaluate the basic scenarios. Do they relate to the goal? Do
                  they have internal consistency? Do they have an archetypal
                  character? Do they represent relatively stable results?
                </li>
                <li>
                  Converge towards decision-making scenarios. Follow the
                  previous seven steps in the context of a recurring process
                  until you arrive at scenarios that address the fundamental
                  issues of the business. Evaluate the positive and negative
                  points of each scenario and then rank them one by one based on
                  an assessment of their probability.
                </li>
              </ol>
              <p>Conclusion:</p>
              <p>
                Today, and in the future, it may not be so critical what
                companies possess and produce, but it is crucial to ask about
                their ability to detect disruptions, predict chaos, and manage
                risk. Identifying and managing risk is anything but an easy
                task. Scenario planning and strategies for addressing expected
                risks, and conversely for exploiting opportunities, presuppose
                that business leaders will instill new forms of strategic
                behavior and principles in the organization they manage.
              </p>
              <p>
                A basic prerequisite? Some of their leaders must abandon
                omniscience.
              </p>
            </article>

            <!-- <div class="row">
                <div class="col-sm-6">
                  <img
                    src="../../assets/img/blog/4.jpg"
                    class="article-bottom-img"
                    alt="blog image"
                  />
                </div>
                <div class="col-sm-6">
                  <img
                    src="../../assets/img/blog/5.jpg"
                    class="article-bottom-img"
                    alt="blog image"
                  />
                </div>
              </div> -->

            <!-- <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
                ipsum suspendisse ultrices gravida. Risus commodo viverra
                maecenas accumsan lacus vel facilisis.
              </p>

              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
                ipsum suspendisse ultrices gravida. Risus commodo viverra
                maecenas accumsan lacus vel facilisis.
              </p> -->

            <!-- <div class="blog-nav">
                <div class="prev-btn">
                  <a href="#">Previous</a>
                </div>
                <div class="next-btn text-end">
                  <a href="#">Next</a>
                </div>
              </div> -->

            <!-- <div class="blog-comment">
                <h3>Drop your comment</h3>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Your Full Name"
                      />
                    </div>
                  </div>

                  <div class="col-md-12">
                    <div class="form-group">
                      <input
                        type="email"
                        class="form-control"
                        placeholder="Your Email"
                      />
                    </div>
                  </div>

                  <div class="col-md-12">
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Subject"
                      />
                    </div>
                  </div>

                  <div class="col-md-12">
                    <div class="form-group">
                      <textarea
                        class="message-field"
                        cols="30"
                        rows="5"
                        placeholder="Comment Here"
                      ></textarea>
                    </div>
                  </div>

                  <div class="col-md-12">
                    <button type="submit" class="default-btn contact-btn">
                      Post A Comment
                    </button>
                  </div>
                </div>
              </div> -->
          </div>
        </div>
        <!-- <div class="col-lg-4"> -->
        <!-- <div class="blog-search">
              <form>
                <input type="text" placeholder="Search" />
                <button><i class="flaticon-search"></i></button>
              </form>
            </div> -->

        <!-- <div class="recent-blog">
              <h3>Recent Blogs</h3>

              <article class="recent-post">
                <a href="#"
                  ><img
                    src="../../assets/img/blog/recent-1.png"
                    alt="recent post image"
                /></a>
                <h3>
                  <a href="#">Content writing demand day by day increasing</a>
                </h3>
                <ul>
                  <li>
                    <i class="icofont-user-alt-3"></i>
                    Admin
                  </li>
                  <li>
                    <i class="icofont-user-alt-3"></i>
                    Jan 03, 2021
                  </li>
                </ul>
              </article>

              <article class="recent-post">
                <a href="#"
                  ><img
                    src="../../assets/img/blog/recent-2.png"
                    alt="recent post image"
                /></a>
                <h3>
                  <a href="#"
                    >Don't buy a tech gift until you read these rules image</a
                  >
                </h3>
                <ul>
                  <li>
                    <i class="icofont-user-alt-3"></i>
                    Admin
                  </li>
                  <li>
                    <i class="icofont-user-alt-3"></i>
                    Jan 03, 2021
                  </li>
                </ul>
              </article>

              <article class="recent-post">
                <a href="#"
                  ><img
                    src="../../assets/img/blog/recent-3.png"
                    alt="recent post image"
                /></a>
                <h3>
                  <a href="#">The next genaration IT will change the world</a>
                </h3>
                <ul>
                  <li>
                    <i class="icofont-user-alt-3"></i>
                    Admin
                  </li>
                  <li>
                    <i class="icofont-user-alt-3"></i>
                    Jan 03, 2021
                  </li>
                </ul>
              </article>
            </div> -->
        <!--
            <div class="blog-category">
              <h3>Category</h3>

              <ul>
                <li>
                  <a href="#">
                    Business Strategy
                    <i class="icofont-arrow-right"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    Full Secured
                    <i class="icofont-arrow-right"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    Business Policy
                    <i class="icofont-arrow-right"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    Software Developmet
                    <i class="icofont-arrow-right"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    Time Saving
                    <i class="icofont-arrow-right"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    UIUX Design
                    <i class="icofont-arrow-right"></i>
                  </a>
                </li>
              </ul>
            </div>

            <div class="photo-gallery">
              <h3>Photo Gallery</h3>

              <a href="../../assets/img/blog/photo-1.jpg" class="popup-gallery">
                <img src="../../assets/img/blog/photo-1.jpg" alt="photo-gallery" />
              </a>
              <a href="../../assets/img/blog/photo-2.jpg" class="popup-gallery">
                <img src="../../assets/img/blog/photo-2.jpg" alt="photo-gallery" />
              </a>
              <a href="../../assets/img/blog/photo-3.jpg" class="popup-gallery">
                <img src="../../assets/img/blog/photo-3.jpg" alt="photo-gallery" />
              </a>
              <a href="../../assets/img/blog/photo-4.jpg" class="popup-gallery">
                <img src="../../assets/img/blog/photo-4.jpg" alt="photo-gallery" />
              </a>
              <a href="../../assets/img/blog/photo-5.jpg" class="popup-gallery">
                <img src="../../assets/img/blog/photo-5.jpg" alt="photo-gallery" />
              </a>
              <a href="../../assets/img/blog/photo-6.jpg" class="popup-gallery">
                <img src="../../assets/img/blog/photo-6.jpg" alt="photo-gallery" />
              </a>
              <a href="../../assets/img/blog/photo-7.jpg" class="popup-gallery">
                <img src="../../assets/img/blog/photo-7.jpg" alt="photo-gallery" />
              </a>
              <a href="../../assets/img/blog/photo-8.jpg" class="popup-gallery">
                <img src="../../assets/img/blog/photo-8.jpg" alt="photo-gallery" />
              </a>
            </div> -->

        <!-- <div class="tags">
              <h3>Tags</h3>
              <a href="#">UX/UI</a>
              <a href="#">Sturtup</a>
              <a href="#">App</a>
              <a href="#">Design</a>
              <a href="#">Business</a>
              <a href="#">UX</a>
              <a href="#">Writing</a>
              <a href="#">Web</a>
            </div> -->
        <!-- </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";

// Export component definition
export default {
  setup() {
    const { t, locale } = useI18n();
    return { t, locale };
  },
};
</script>
