<template>
  <!-- About Section Start -->
  <section class="about-section pb-100">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-5">
          <div class="about-image">
            <img
              src="assets/img/full-images/Erga-gis-egaiou.jpg"
              alt="about image"
            />
          </div>
        </div>

        <div class="col-lg-6 offset-lg-1">
          <div class="about-text">
            <div class="section-title">
              <span>{{ $t("services.erga_aigaiou.subtitle") }} </span>
              <h2>{{ $t("services.erga_aigaiou.title") }}</h2>
            </div>

            <p>
              {{ $t("services.erga_aigaiou.text") }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- About Section End -->

  <!-- Theory Section Start -->
  <!-- <div class="theory-section theory-style-two">
      <div class="container">
        <div class="row theory-area">
          <div class="col-lg-7">
            <div class="theory-text">
              <h2>My Theory is Customer Service is First</h2>
              <p>
                Quis ipsum suspendisse ultrices gravida. Risus commodo Quis
                ipsum suspendisse ultrices gravida. Risus commodo viverra
                maecenas accumsan.
              </p>

              <div class="signature-box">
                <img src="assets/img/signature.png" alt="signature" />
                <p>Babatunde Smithi</p>
                <span>Founder and CEO</span>
              </div>
            </div>
          </div>
          <div class="theory-img">
            <img src="assets/img/theory-img.png" alt="theory image" />
          </div>
        </div>
      </div>
    </div> -->
  <!-- Theory Section End -->

  <!-- Navbar Area Start -->

  <!-- Navbar Area End -->

  <!-- Modal Start  -->

  <!-- Modal End -->

  <!-- Service Title Section Start -->

  <!-- Service Title Section End -->

  <!-- Service Details Section Start -->
  <section class="service-style-four pt-100 pb-70 justify-center">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <div class="service-details-area">
            <div class="service-details-img">
              <!-- <img
                  src="assets/img/service-details.jpg"
                  alt="service details"
                /> -->
            </div>
            <!-- <h3>Online Business</h3> -->

            <div class="row">
              <div class="col-lg-6 col-md-6">
                <img
                  src="assets/img/portfolio/erga-gis-aigaiou/1.jpeg"
                  class="service-btm-img"
                  alt="service details"
                />
              </div>

              <div class="col-lg-6 col-md-6">
                <img
                  src="assets/img/portfolio/erga-gis-aigaiou/2.jpeg"
                  class="service-btm-img"
                  alt="service details"
                />
              </div>
              <div class="col-lg-6 col-md-6">
                <img
                  src="assets/img/portfolio/erga-gis-aigaiou/3.jpeg"
                  class="service-btm-img"
                  alt="service details"
                />
              </div>

              <div class="col-lg-6 col-md-6">
                <img
                  src="assets/img/portfolio/erga-gis-aigaiou/4.jpeg"
                  class="service-btm-img"
                  alt="service details"
                />
              </div>
              <div class="col-lg-6 col-md-6">
                <img
                  src="assets/img/portfolio/erga-gis-aigaiou/5.jpeg"
                  class="service-btm-img"
                  alt="service details"
                />
              </div>

              <div class="col-lg-6 col-md-6">
                <img
                  src="assets/img/portfolio/erga-gis-aigaiou/6.jpeg"
                  class="service-btm-img"
                  alt="service details"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { useI18n } from "vue-i18n";

// Export component definition
export default {
  setup() {
    const { t } = useI18n();
    return { t };
  },
};
</script>
