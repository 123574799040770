<template>
  <section class="blog-section pt-100 pb-70">
    <div class="container">
      <div class="section-title text-center">
        <span>{{ $t("our-blog-title") }}</span>
        <h2>{{ $t("our-blog-subtitle") }}</h2>
      </div>

      <div class="row">
        <div class="col-lg-4 col-md-6">
          <div class="blog-card">
            <div class="blog-img">
              <router-link :to="{ name: 'blogOne' }">
                <img src="assets/img/blog/1.jpeg" alt="blog image" />
              </router-link>
              <div class="blog-date">
                <span>27 Δεκ</span>
              </div>
            </div>
            <div class="blog-text">
              <router-link :to="{ name: 'blogOne' }">
                <h3>{{ $t("blog-title-1") }}</h3>
              </router-link>
              <div class="post-info">
                <a href="#">
                  <p>{{ $t("blog_writter") }}</p>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6">
          <div class="blog-card">
            <div class="blog-img">
              <router-link :to="{ name: 'blogTwo' }">
                <img src="assets/img/blog/2.jpeg" alt="blog image" />
              </router-link>
              <div class="blog-date">
                <span>18 Aυγ</span>
              </div>
            </div>
            <div class="blog-text">
              <router-link :to="{ name: 'blogTwo' }">
                <h3>{{ $t("blog-title-2") }}</h3>
              </router-link>
              <div class="post-info">
                <a href="#">
                  <p>{{ $t("blog_writter") }}</p>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
          <div class="blog-card">
            <div class="blog-img">
              <router-link :to="{ name: 'blogThree' }">
                <img src="assets/img/blog/2.jpeg" alt="blog image" />
              </router-link>
              <div class="blog-date">
                <span>8 Aυγ</span>
              </div>
            </div>
            <div class="blog-text">
              <router-link :to="{ name: 'blogThree' }">
                <h3>{{ $t("blog-title-3") }}</h3>
              </router-link>
              <div class="post-info">
                <a href="#">
                  <p>{{ $t("blog_writter") }}</p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { useI18n } from "vue-i18n";

// Export component definition
export default {
  setup() {
    const { t } = useI18n();
    return { t };
  },
};
</script>
